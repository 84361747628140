import React, { FC } from "react";

interface OwnPropsDatenschutz {}

type PropsDatenschutz = OwnPropsDatenschutz;

const Datenschutz: FC<PropsDatenschutz> = props => {
  return <div style={{ width: "100%", height: "100vh", backgroundColor: "white" }}>Hier kommt der Datenschutz</div>;
};

export default Datenschutz;
