import React, { Component, FC, Ref } from "react";
import { LContext } from "../../Framework/Languagechanger/Languagechanger";
import { Form, Input, Steps, Button, Row, Col, Icon, Select, Modal, Tooltip, message, Popover } from "antd";
import MediaQuery from "react-responsive";
import Box from "../../Framework/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartArrowDown,
  faArrowLeft,
  faArrowRight,
  faWindowClose,
  faMoneyCheckAlt,
  faEuroSign,
  faSort,
  faCalendarAlt
} from "@fortawesome/free-solid-svg-icons";
import axios, { AxiosRequestConfig } from "axios";
import OurDatepicker from "../../Framework/OurComps/datepicker/OurDatepicker";
import moment from "moment";
import { faCalendarPlus, faImages, faImage, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import Myicon, { OwnProps as MyIconProps } from "../../Icons/Myicon";
import { observer, Observer } from "mobx-react";
import { observable } from "mobx";
import { Link } from "react-router-dom";

const { Option, OptGroup } = Select;

require("moment/min/locales.min");
// @ts-ignore
const locale = window.navigator.language || window.navigator.userLanguage;

moment.locale(locale);

let xy = [
  {
    name: "Erwachsenenpool",
    bereiche: [
      {
        name: "Bereich A",
        equipment: [
          {
            name: "Liege",
            preis: "8.00",
            currency: "€",
            reihen: [
              {
                reihennummer: 1,
                anzahl: 0
              },
              {
                reihennummer: 2,
                anzahl: 3
              }
            ]
          },
          {
            name: "Pavillion",
            preis: "8.00",
            currency: "€",
            reihen: [
              {
                reihennummer: 1,
                anzahl: 10
              },
              {
                reihennummer: 2,
                anzahl: 5
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: "Kinderpool",
    bereiche: [
      {
        name: "Bereich A",
        equipment: [
          {
            name: "Liege",
            preis: "8.00",
            currency: "€",
            reihen: [
              {
                reihennummer: 1,
                anzahl: 0
              },
              {
                reihennummer: 2,
                anzahl: 0
              }
            ]
          },
          {
            name: "Pavillion",
            preis: "8.00",
            currency: "€",
            reihen: [
              {
                reihennummer: 1,
                anzahl: 10
              },
              {
                reihennummer: 2,
                anzahl: 5
              }
            ]
          }
        ]
      }
    ]
  }
];

class AvailableList {}

class Data {
  @observable pickedDays: string[] = [];
  @observable currentstep: number = 0;
  @observable modalbereich: boolean = false;
  @observable modalBild: MyIconProps["name"] = "Bild1";
}

interface OwnProps {
  id?: string;
  onCancel: () => void;
}
interface IUser {
  /*VorNachname: string;
  EmailAdresse: string;
  RegBetreiber: string;
  Land: string;
  StrasseNummer: string;
  Ort: string;
  Telefon: string;*/
}
type Props = OwnProps;

@observer
class Buchung extends Component<Props> {
  data: Data = new Data();
  dataxy = xy;
  static defaultProps = {
    id: ""
  };

  @observable modalOpen: boolean = false;

  filterOpen = () => {
    let data = "";

    if (this.modalOpen === false) {
      this.modalOpen = true;
    } else {
      this.modalOpen = false;
    }
    //window.setTimeout(() => (this.modalOpenmap = true), 1000); hhs
  };

  /*checkrequired = () => {
    let status = true;
    Object.values(this.data.data).map(v => {
      if (v == "") status = false;
    });
    return status;
  };
  save = () => {
    let datapath = Object.values(this.data.data)
      .map(v => {
        return "/" + (v as string).toString().trim();
      })
      .join("");
    if (window.location.hostname == "localhost") {
      axios.get("http://localhost:2000/register" + datapath).then(response => {
        if (response.data) this.setState({ checkeduser: true });
        else alert("Fehler");
      });
    } else {
      axios.get("/register", this.data.data as AxiosRequestConfig);
    }
  };*/

  removeDateFromList = (element: string) => {
    let index = this.data.pickedDays.findIndex(e => e === element);
    if (index > -1) {
      this.data.pickedDays.splice(index, 1);
      this.forceUpdate();
    }
  };

  render() {
    return (
      <MediaQuery minWidth={550}>
        {matches => (
          <LContext.Consumer>
            {({ l, currentl }) => (
              <Observer>
                {() => (
                  <>
                    {/*   <Modal
                      style={{ top: "20px", maxWidth: "800px" }}
                      width={"90vw"}
                      visible={this.modalOpen}
                      onCancel={() => {
                        this.modalOpen = false;
                      }}
                      title={l("Filter")}
                      footer={[
                        <Button
                          key="back"
                          onClick={() => {
                            this.modalOpen = false;
                          }}
                        >
                          {l("Filter setzen")}
                        </Button>
                      ]}
                    >
                      {this.dataxy.map((e: any, index: number) => (
                        <>
                          <span>{e.name}</span>
                        </>
                      ))}
                    </Modal>*/}
                    {/*<div style={{ padding: "10px", backgroundColor: "white" }}>*/}
                    <div>
                      <Steps current={this.data.currentstep} size={"small"} direction={matches ? "horizontal" : "vertical"}>
                        <Steps.Step
                          onClick={() => {
                            this.data.currentstep = 0;
                          }}
                          title={l("AuswahlDatum")}
                          style={{ cursor: "pointer" }}
                        />
                        <Steps.Step
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (this.data.pickedDays.length > 0) this.data.currentstep = 1;
                          }}
                          title={l("AuswahlLiegen")}
                        />
                        {/*<Steps.Step title={l("Warenkorb")} />*/}
                      </Steps>

                      <div className={"steps-content"} style={{ marginTop: matches ? "16px" : "-12px" }}>
                        {this.data.currentstep === 0 ? (
                          <>
                            <div style={{ width: "238px" }}>
                              <div>
                                <OurDatepicker value={this.data.pickedDays} />
                              </div>
                              <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <Button
                                  style={{ width: "70px", marginTop: "10px" }}
                                  disabled={this.data.pickedDays.length < 1}
                                  type={"danger"}
                                  onClick={() => {
                                    this.data.pickedDays.splice(0, this.data.pickedDays.length);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} size={"lg"} color={"white"} />
                                </Button>
                                <Button
                                  style={{ width: "70px", marginTop: "10px" }}
                                  type={"danger"}
                                  onClick={() => {
                                    this.data.pickedDays.splice(0, this.data.pickedDays.length);
                                    this.data.currentstep = 0;
                                    this.props.onCancel();
                                  }}
                                >
                                  <FontAwesomeIcon icon={faWindowClose} size={"lg"} color={"white"} />
                                </Button>
                                <Button
                                  style={{ width: "70px", marginTop: "10px" }}
                                  disabled={this.data.pickedDays.length < 1}
                                  type={"primary"}
                                  onClick={() => {
                                    this.data.currentstep = 1;
                                  }}
                                >
                                  <FontAwesomeIcon icon={faArrowRight} size={"lg"} color={"white"} />
                                </Button>
                              </div>
                            </div>
                          </>
                        ) : this.data.currentstep === 1 ? (
                          <Form layout={"vertical"}>
                            <div style={{ marginBottom: "5px" }}>
                              <Popover
                                visible={this.modalOpen}
                                placement="bottomLeft"
                                title={l("Filter")}
                                content={
                                  <div style={{ maxWidth: "75vw", minWidth: "250px" }}>
                                    <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                                      {/*{this.dataxy.map((e: any, index: number) => (
                                        <>
                                          <span>{e.name}</span>
                                        </>
                                    ))}*/}
                                      <Select style={{ minWidth: "240px", margin: "5px" }} />
                                      <Select style={{ minWidth: "240px", margin: "5px" }} />
                                      <Button
                                        type={"primary"}
                                        style={{ minWidth: "110px", margin: "5px", marginBottom: "0px" }}
                                        key="back"
                                        onClick={() => {
                                          this.modalOpen = false;
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faArrowRight} size={"lg"} color={"white"} style={{ cursor: "pointer" }} />
                                      </Button>
                                    </div>
                                  </div>
                                }
                              >
                                <Button style={{ width: "100px", marginBottom: "10px" }} type={"primary"} onClick={this.filterOpen}>
                                  {l("Filter")}
                                  {/*<FontAwesomeIcon icon={faSort} size={"lg"} color={"white"} style={{ marginLeft: "10px", cursor: "pointer" }} />*/}
                                </Button>
                              </Popover>
                              <Button
                                style={{ width: "70px", float: "right" }}
                                type={"danger"}
                                onClick={() => {
                                  this.data.pickedDays.splice(0, this.data.pickedDays.length);
                                  this.data.currentstep = 0;
                                  this.props.onCancel();
                                }}
                              >
                                <FontAwesomeIcon icon={faWindowClose} size={"lg"} color={"white"} />
                              </Button>
                            </div>

                            <div>
                              {this.data.pickedDays.map(d => (
                                <>
                                  <table
                                    style={{
                                      width: "100%",
                                      maxWidth: "800px",
                                      color: "black",
                                      padding: "4px",
                                      borderRight: "1px solid rgba(228, 233, 237, 1)",
                                      borderLeft: "1px solid rgba(228, 233, 237, 1)",
                                      borderBottom: "1px solid rgba(228, 233, 237, 1)",
                                      backgroundColor: "white"
                                    }}
                                  >
                                    <tbody>
                                      <tr style={{ backgroundColor: "rgba(228, 233, 237, 1)", fontWeight: "bold", height: "26px" }}>
                                        <td style={{ paddingLeft: "4px" }}>
                                          {moment(d, "YYYY-MM-DD").format(
                                            moment
                                              .localeData()
                                              .longDateFormat("L")
                                              .toLocaleUpperCase()
                                          )}
                                        </td>
                                        <td style={{ paddingRight: "10px", textAlign: "right" }}>
                                          <span
                                            onClick={this.removeDateFromList.bind(this, d)}
                                            style={{ cursor: "pointer", paddingLeft: "2px", paddingRight: "2px" }}
                                          >
                                            <FontAwesomeIcon icon={faTrashAlt} size={"lg"} color={"red"} />
                                          </span>
                                        </td>
                                      </tr>
                                      {this.dataxy.map((e: any, index: number) => (
                                        <>
                                          <tr style={{}}>
                                            <td style={{ paddingLeft: "4px" }}>
                                              <span style={{ fontWeight: "bold" }} key={e.index}>
                                                {e.name}
                                              </span>
                                              <span
                                                onClick={() => {
                                                  /* this.data.modalbereich = true;
                                                this.data.modalBild = "pool_1";*/
                                                }}
                                                style={{ cursor: "pointer", paddingLeft: "2px", paddingRight: "2px" }}
                                              >
                                                <FontAwesomeIcon icon={faImage} size={"lg"} color={"grey"} style={{ marginLeft: "10px" }} />
                                              </span>
                                            </td>
                                            <td style={{ paddingLeft: "4px" }}>
                                              <span>{l("Anzahl")}</span>
                                            </td>
                                          </tr>
                                          {e.bereiche.map((f: any, indexbereiche: number) => (
                                            <>
                                              <tr style={{}}>
                                                <td colSpan={2} style={{ paddingLeft: "4px" }}>
                                                  <span style={{ color: "grey", textDecoration: "underline" }} key={indexbereiche}>
                                                    {f.name}
                                                  </span>
                                                  <span
                                                    onClick={() => {
                                                      /* this.data.modalbereich = true;
                                                                this.data.modalBild = "pool_1";*/
                                                    }}
                                                    style={{ cursor: "pointer", paddingLeft: "2px", paddingRight: "2px" }}
                                                  >
                                                    {/*<FontAwesomeIcon icon={faImage} size={"lg"} color={"grey"} style={{ marginLeft: "10px" }} />*/}
                                                  </span>
                                                </td>
                                              </tr>
                                              {f.equipment.map((g: any, indexequimpent: number) => (
                                                <>
                                                  <tr style={{}}>
                                                    <td style={{ paddingLeft: "4px", width: "100%" }}>
                                                      <span key={indexequimpent}>
                                                        {g.name} {g.preis} {g.currency}
                                                      </span>
                                                      <span onClick={() => {}} style={{ cursor: "pointer", paddingLeft: "2px", paddingRight: "2px" }}>
                                                        {/*<FontAwesomeIcon icon={faImage} size={"lg"} color={"grey"} style={{ marginLeft: "10px" }} />*/}
                                                      </span>
                                                    </td>
                                                    <td style={{ paddingLeft: "4px", width: "100%" }}>
                                                      <Select
                                                        placeholder={0}
                                                        size={"small"}
                                                        style={{ paddingRight: "5px", width: "80px", marginBottom: "4px" }}
                                                        allowClear={true}
                                                      >
                                                        {g.reihen.map((h: any, indexreihen: number) => (
                                                          <OptGroup key={h.reihennummer} label={"Reihe " + h.reihennummer}>
                                                            {[...Array(h.anzahl).keys()].map((j: any) => (
                                                              <Option key={j + 1} value={j + 1}>
                                                                {j + 1}
                                                              </Option>
                                                            ))}
                                                          </OptGroup>
                                                        ))}
                                                      </Select>
                                                      {/*{g.reihen.map((h: any, indexeq: number) => (
                                                        <>
                                                          <span>
                                                            {h.reihennummer}:{h.anzahl}
                                                          </span>
                                                        </>
                                                      ))}*/}
                                                    </td>
                                                  </tr>
                                                </>
                                              ))}
                                            </>
                                          ))}
                                        </>
                                      ))}
                                    </tbody>
                                  </table>
                                </>
                              ))}
                              <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
                                <Button
                                  style={{ width: "70px" }}
                                  type={"primary"}
                                  onClick={() => {
                                    this.data.currentstep = 0;
                                  }}
                                >
                                  <FontAwesomeIcon icon={faCalendarAlt} size={"lg"} color={"white"} />
                                </Button>
                                <Button
                                  disabled={this.data.pickedDays.length < 1}
                                  style={{ width: "70px" }}
                                  type={"primary"}
                                  onClick={() => {
                                    //this.data.currentstep = 0;
                                    message.success(l("PlusWarenkorb"));
                                  }}
                                >
                                  <FontAwesomeIcon icon={faCartArrowDown} size={"lg"} color={"white"} />
                                </Button>
                                <Link to="./Warenkorb">
                                  <Button
                                    disabled={this.data.pickedDays.length < 1}
                                    style={{ width: "70px" }}
                                    type={"primary"}
                                    onClick={() => {
                                      this.data.modalbereich = true;
                                      this.data.modalBild = "logointech";
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faEuroSign} size={"lg"} color={"white"} />
                                  </Button>
                                </Link>
                              </div>
                            </div>
                          </Form>
                        ) : (
                          <table
                            style={{
                              width: "100%",
                              color: "white"
                            }}
                          >
                            <tbody>
                              <tr style={{ padding: "4px", borderBottom: "1px solid white" }}>
                                <td colSpan={2}>
                                  <h3 style={{ color: "white" }}>{l("Kalk_Ü")}</h3>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        )}
                      </div>

                      <Modal
                        closable={false}
                        onCancel={() => (this.data.modalbereich = false)}
                        visible={this.data.modalbereich}
                        footer={[
                          <Button key="back" onClick={() => (this.data.modalbereich = false)}>
                            {l("Schließen")}
                          </Button>
                        ]}
                      >
                        <Myicon name={this.data.modalBild} />
                      </Modal>
                    </div>
                  </>
                )}
              </Observer>
            )}
          </LContext.Consumer>
        )}
      </MediaQuery>
    );
  }
}

export default Buchung;
