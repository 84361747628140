import React, { FC, StyleHTMLAttributes } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";

interface OwnPropsOurFontAwsomeIcon {
  icon: IconProp;
  color?: string;
  style?: any;
  onClick?: () => void;
  size?: SizeProp;
}

type PropsOurFontAwsomeIcon = OwnPropsOurFontAwsomeIcon;

export const OurFontAwsomeIcon: FC<PropsOurFontAwsomeIcon> = props => {
  return (
    <>
      <div
        style={{ display: "flex" }}
        onClick={() => {
          if (props.onClick !== undefined) props.onClick();
        }}
      >
        <div style={{ width: "2em", display: "flex", justifyContent: "center" }}>
          <FontAwesomeIcon style={props.style || {}} icon={props.icon} size={props.size !== undefined ? props.size : "lg"} color={props.color || "white"} />
        </div>
        {props.children}
      </div>
    </>
  );
};
