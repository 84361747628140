import React, { Component, FC } from "react";
import { observer, Observer } from "mobx-react";
import { LContext } from "../../Framework/Languagechanger/Languagechanger";
import OAuth from "../../Framework/OAuth/OAuth";
import { Button, Card, Col, Form, Input, Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTimes, faTimes } from "@fortawesome/free-solid-svg-icons";
import { observable } from "mobx";

interface OwnProps {
  // isSignedIn: boolean;
}

type Props = OwnProps;

type State = Readonly<{
  ModalLogin: boolean;
  ModalPasswort: boolean;
}>;

@observer
class LoginModal extends Component<Props, State> {
  state: State = {
    //polygons: []
    ModalLogin: false,
    ModalPasswort: false
  };
  //static defaultProps = {};

  render() {
    return (
      <LContext.Consumer>
        {({ l, currentl }) => (
          <Observer>
            {() => (
              <>
                <span onClick={() => this.setState({ ModalLogin: true })} style={{ cursor: "pointer" }}>
                  <FontAwesomeIcon icon={faUserTimes} size={"lg"} color={"white"} />
                </span>
                <Modal
                  style={{ marginTop: "-84px" }}
                  closeIcon={[
                    <span onClick={() => this.setState({ ModalLogin: false })}>
                      <FontAwesomeIcon icon={faTimes} size={"lg"} />
                    </span>
                  ]}
                  visible={this.state.ModalLogin}
                  footer={null}
                  /*footer={[
                    <Button key="back" onClick={() => this.setState({ ModalLogin: false })} type="primary">
                      {l("Schließen")}
                    </Button>
                  ]}*/
                >
                  <h3 style={{ textAlign: "center", color: "grey", marginTop: "-10px" }}>{l("LoginAuth")}</h3>
                  <OAuth />
                  <h3 style={{ textAlign: "center", color: "grey" }}>{l("LoginMyBeachCouch")}</h3>
                  <Card style={{ width: "100%", backgroundColor: "transparent" }}>
                    <Form.Item style={{ marginTop: "-10px" }}>
                      <Input placeholder={l("EmailAdresse")} allowClear={true} style={{ paddingTop: "-10px" }} />
                    </Form.Item>
                    <Form.Item>
                      <Input placeholder={l("Passwort")} allowClear={true} style={{ marginTop: "-10px" }} />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        style={{ width: "100%" }}
                        onClick={() => {
                          //this.data.mybeachanmeldung = 2;
                        }}
                        type="primary"
                      >
                        {l("Login")}
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        style={{ width: "100%", marginTop: "8px" }}
                        onClick={() => {
                          //this.data.mybeachanmeldung = 1;
                        }}
                        type="primary"
                      >
                        {l("ButtonNewAccount")}
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        style={{ width: "100%", marginTop: "8px" }}
                        onClick={() => {
                          this.setState({ ModalPasswort: true, ModalLogin: false });
                        }}
                      >
                        {l("PasswortVergessen")}
                      </Button>
                    </Form.Item>
                  </Card>
                </Modal>
                <Modal
                  closable={false}
                  visible={this.state.ModalPasswort}
                  footer={[
                    <Button key="back" onClick={() => this.setState({ ModalPasswort: false })} type="primary">
                      {l("Schließen")}
                    </Button>
                  ]}
                >
                  {l("NeuesPasswort")}
                  <Form.Item>
                    <Input placeholder={l("EmailAdresse")} allowClear={true} />
                  </Form.Item>
                  <Form.Item>
                    <span style={{ float: "right" }}>
                      <Button
                        onClick={() => {
                          //this.data.mybeachanmeldung = 1;
                        }}
                        type="primary"
                      >
                        {l("SendNeuesPasswort")}
                      </Button>
                    </span>
                  </Form.Item>
                </Modal>
              </>
            )}
          </Observer>
        )}
      </LContext.Consumer>
    );
  }
}

export default LoginModal;
