export function toMoneyFormat(v: number, moneytype = "€"): string {
  let vnew = v
    .toFixed(2)
    .split(/(?=(?:\d{3})+(?:\.|$))/g)
    .join(",");
  vnew += " " + moneytype;
  return vnew;
}

export function fromMoneyFormat(v: string): number {
  return parseFloat(replaceAll(v, ",", "").replace(/(?!-)[^0-9.]/g, ""));
}

export function replaceAll(str: string, find: string, replace: string): string {
  return str.replace(new RegExp(find, "g"), replace);
}
