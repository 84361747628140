import React, { Component, FC } from "react";
import Languagechanger, { LContext } from "../../Framework/Languagechanger/Languagechanger";
import Buchung from "../BuchungsSeite/Buchung";
import {
  Button,
  Col,
  Drawer,
  Icon,
  Input,
  Layout,
  Row,
  Modal,
  Tooltip,
  Form,
  Tabs,
  Calendar,
  Badge,
  Select,
  AutoComplete,
  Spin,
  Steps,
  message,
  Card
} from "antd";

import { HashLink as AnchorLink } from "react-router-hash-link";
import { RouteComponentProps, withRouter, BrowserRouter, Link } from "react-router-dom";
import { NavScroll, NavScrollItem } from "../../NavScroll/NavScroll";

import Box from "../../Framework/Box";

import "react-datepicker/dist/react-datepicker.css";
import { Carousel } from "react-responsive-carousel";
import Myicon from "../../Icons/Myicon";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImages, faCalendarAlt, faAddressCard } from "@fortawesome/free-regular-svg-icons";
import {
  faCartArrowDown,
  faMapMarkerAlt,
  faShoppingCart,
  faUmbrellaBeach,
  faClipboardList,
  faTrashAlt,
  faImage,
  faWindowClose,
  faEuroSign,
  faArrowLeft,
  faArrowRight
} from "@fortawesome/free-solid-svg-icons";
import { OurFontAwsomeIcon } from "../../Framework/OurComps/Icons/OurFontAwsomeIcon";
import axios, { AxiosRequestConfig } from "axios";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { observer, Observer } from "mobx-react";
import { observable } from "mobx";

import MediaQuery from "react-responsive";
import moment from "moment";
import Header from "../../Framework/Header";
/*import firebase from "firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";*/
import { InputSubmitt } from "../AnbieterSeite/Anmeldung";
import OAuth from "../../Framework/OAuth/OAuth";
import CountrySelect from "../../Framework/CountrySelect/CountrySelect";

const { TabPane } = Tabs;

const { Option, OptGroup } = Select;

const { Search } = Input;

const searcharray: any = ["d", "d", "Club Calmeria, Türkei, Belek", "Test", "Club Test, Türkei, Belek"];

const childrenarray: any = ["Liege", "Pavillion", "Strandkorb"];

const children: any = [];

// @ts-ignore
childrenarray.map(val => {
  children.push(<Option key={val}>{val}</Option>);
});

interface OwnProps extends RouteComponentProps {}

type Props = OwnProps;

type State = Readonly<{
  navopen: boolean;
  searcharray: any[];
  dataSource: any[];
  hotelData: any[];
  fetching: boolean;
  signInSuccess: boolean;
}>;

class Warenkorb extends Component<Props, State> {
  readonly state: State = {
    navopen: false,
    searcharray: [],
    dataSource: [],
    hotelData: [],
    fetching: false,
    signInSuccess: true
  };
  searchdebouncer = new Subject();
  static defaultProps = {};
  constructor(props: any) {
    super(props);

    this.searchdebouncer.pipe(debounceTime(300)).subscribe(x => this.fetchUser(x as string));
  }

  fetchUser = (value: string) => {
    if (value.length >= 2) {
      this.setState({ hotelData: [], fetching: true });

      let local = "";

      if (window.location.hostname === "localhost") {
        local = "http://localhost:2000";
      }
      axios.get(local + "/searchHotelsBeach/" + value).then(response => {
        console.log(response.data);
        if (response.data) this.setState({ hotelData: response.data, fetching: false });
        else alert("Fehler");
      });
    } else {
      this.setState({ hotelData: [] });
    }
  };

  render() {
    // @ts-ignore
    return (
      <LContext.Consumer>
        {({ l, currentl }) => (
          <>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "45px", overflow: "hidden", minHeight: "calc(100vh - 125px)" }}>
              <div style={{ backgroundColor: "transparent", display: "inline-block", width: "94%" }}>
                <WarenkorbKasse />
              </div>
            </div>
          </>
        )}
      </LContext.Consumer>
    );
  }
}

interface OwnPropsDrawerMenuNavItem extends RouteComponentProps {
  onClick?: () => void;
  topath?: string;
}

type PropsDrawerMenuNavItem = OwnPropsDrawerMenuNavItem;

const DrawerMenuNavIteminner: FC<PropsDrawerMenuNavItem> = props => {
  return (
    <>
      <AnchorLink
        scroll={el => {
          //el.scrollIntoView({ behavior: "smooth", block: "start" });
          window.scrollTo({ left: 0, top: el.offsetTop - 45, behavior: "smooth" });
        }}
        to={props.location.pathname + "#" + (props.topath !== undefined ? props.topath : "")}
      >
        <div onClick={props.onClick} className={"DrawerMenuNavItem"}>
          {props.children}
        </div>
      </AnchorLink>
    </>
  );
};

let xy = [
  {
    datum: "2019-10-15",
    warenkorb: [
      {
        hotelstrand: "Calimera Beach Resort",
        name: "Erwachsenenpool",
        bereich: "Bereich A",
        equipment: "Pavillion",
        reihe: "1",
        anzahl: "2",
        preis: "8.00",
        currency: "€"
      },
      {
        hotelstrand: "Calimera Beach Resort",
        name: "",
        bereich: "Bereich A",
        equipment: "Liege mit Schirm",
        reihe: "1",
        anzahl: "2",
        preis: "8.33",
        currency: "€"
      }
    ]
  },
  {
    datum: "2019-10-16",
    warenkorb: [
      {
        hotelstrand: "Strand Playa de Americas",
        name: "",
        bereich: "Bereich A",
        equipment: "Liege mit Schirm",
        reihe: "1",
        anzahl: "21",
        preis: "12.12",
        currency: "€"
      }
    ]
  }
];

class Data {
  @observable pickedDays: string[] = [];
  @observable currentstep: number = 0;
  @observable mybeachanmeldung: number = 0;
  @observable ModalPasswort: boolean = false;
}

const DrawerMenuNavItem = withRouter(DrawerMenuNavIteminner);

interface OwnPropsPoolView {}

type PropsPoolView = OwnPropsPoolView;

type StatePoolView = Readonly<{
  //isSignedIn: boolean;
  //signInSuccess?: () => void;
}>;

/*
firebase.initializeApp({
  apiKey: "AIzaSyDNCLuivzv7JPfEqmhrPwiwUQw5kzi96G0",
  authDomain: "beachsofa-1569408736471.firebaseapp.com"
});
*/

@observer
class WarenkorbKasse extends Component<PropsPoolView, StatePoolView> {
  data: Data = new Data();
  dataxy = xy;

  readonly state: StatePoolView = {
    //isSignedIn: false
    //signInSuccess:
  };
  static defaultProps = {};
  statusFormatter = (currrent: number, all: number) => {
    return currrent + " / " + all;
  };

  /*uiConfig = {
    signInFlow: "popup",
    //languageCode: "DE",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.TwitterAuthProvider.PROVIDER_ID
      //firebase.auth.GithubAuthProvider.PROVIDER_ID,
      //firebase.auth.EmailAuthProvider.PROVIDER_ID
    ],
    //signInSuccessUrl: "/#/Warenkorb"
    callbacks: {
      signInSuccessWithAuthResult: (result: any, redirectUrl: any) => {
        console.log(result);
        //console.log(result.additionalUserInfo.profile.email);
        return false;
      }
    }
  };
  componentDidMount(): void {
    firebase.auth().onAuthStateChanged(user => {
      this.setState({ isSignedIn: !!user });
    });
  }*/

  render() {
    //https://api.klarna.com/payments/v1/sessions/<kp_session_id>
    /*const responseGoogle = (response: any) => {
      console.log(response);
    };*/
    //console.log(this.data.ModalPasswort);
    return (
      <MediaQuery minWidth={550}>
        {matches => (
          <LContext.Consumer>
            {({ l, currentl }) => (
              <Observer>
                {() => (
                  <>
                    <Box>
                      <div style={{ width: "100%", display: "inline-block", justifyContent: "center", textAlign: "center" }}>
                        <div style={{ textAlign: "center" }}>{/* <h2>{l("Warenkorb")}</h2>*/}</div>
                        {/*<div style={{ float: "right" }}>
                          <Button type={"primary"}>test</Button>
                        </div>*/}
                      </div>
                      <div style={{ width: "100%", justifyContent: "center" }}>
                        <div
                          style={{
                            maxWidth: "800px",

                            margin: "0 auto"
                          }}
                        >
                          <Steps current={this.data.currentstep} size={"small"} direction={matches ? "horizontal" : "vertical"} style={{ maxWidth: "800px" }}>
                            <Steps.Step
                              onClick={() => {
                                this.data.currentstep = 0;
                                this.data.mybeachanmeldung = 0;
                              }}
                              title={l("Warenkorb")}
                              style={{ cursor: "pointer" }}
                            />
                            <Steps.Step
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                this.data.currentstep = 1;
                              }}
                              title={l("WarenkorbAnmeldung")}
                            />
                            <Steps.Step
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (this.data.pickedDays.length > 0) this.data.currentstep = 2;
                              }}
                              title={l("WarenkorbKasse")}
                            />
                            {/*<Steps.Step title={l("Warenkorb")} />*/}
                          </Steps>

                          <div className={"steps-content"} style={{ marginTop: matches ? "16px" : "-12px" }}>
                            {this.data.currentstep === 0 ? (
                              <>
                                <Form layout={"vertical"}>
                                  <div>
                                    <table
                                      style={{
                                        width: "100%",
                                        maxWidth: "800px",
                                        color: "black",
                                        padding: "4px",
                                        borderRight: "1px solid rgba(228, 233, 237, 1)",
                                        borderLeft: "1px solid rgba(228, 233, 237, 1)",
                                        borderBottom: "1px solid rgba(228, 233, 237, 1)",
                                        backgroundColor: "white"
                                      }}
                                    >
                                      <tbody>
                                        {this.dataxy.map(d => (
                                          <>
                                            <tr style={{ backgroundColor: "rgba(228, 233, 237, 1)", fontWeight: "bold", height: "26px" }}>
                                              <td style={{ paddingLeft: "4px" }}>
                                                {moment(d.datum, "YYYY-MM-DD").format(
                                                  moment
                                                    .localeData()
                                                    .longDateFormat("L")
                                                    .toLocaleUpperCase()
                                                )}
                                              </td>
                                              <td style={{ paddingRight: "10px", textAlign: "right" }}>
                                                {/*<span
                                          onClick={this.removeDateFromList.bind(this, d)}
                                          style={{ cursor: "pointer", paddingLeft: "2px", paddingRight: "2px" }}
                                        >
                                          <FontAwesomeIcon icon={faTrashAlt} size={"lg"} color={"red"} />
                                        </span>*/}
                                              </td>
                                            </tr>
                                            {d.warenkorb.map((e: any, indexw: number) => (
                                              <>
                                                <tr style={{}}>
                                                  <td style={{ paddingLeft: "4px" }}>
                                                    <span style={{ fontWeight: "bold", marginRight: "4px" }}>{e.equipment}</span>
                                                  </td>
                                                  <td style={{ paddingRight: "6px", float: "right" }}>
                                                    <span style={{ marginRight: "4px" }}>
                                                      {/* {l("Anzahl")}: <span style={{ fontWeight: "bold" }}>{e.anzahl}</span>*/}
                                                      {/* <Input
                                                disabled={true}
                                                defaultValue={e.anzahl}
                                                size={"small"}
                                                style={{ width: "60px", marginBottom: "4px", marginTop: "4px", fontSize: "12px" }}
                                              />*/}
                                                      <Select
                                                        disabled={true}
                                                        defaultValue={e.anzahl}
                                                        size={"small"}
                                                        style={{ minWidth: "60px", marginBottom: "4px", marginTop: "4px" }}
                                                        allowClear={true}
                                                      >
                                                        <Option key={e.anzahl} value={e.anzahl}>
                                                          {e.anzahl}
                                                        </Option>
                                                      </Select>
                                                    </span>
                                                  </td>
                                                </tr>
                                                <tr style={{}}>
                                                  <td style={{ paddingLeft: "4px" }}>
                                                    <span>
                                                      ({e.currency} {e.preis})
                                                    </span>
                                                  </td>
                                                  <td style={{ marginRight: "8px", float: "right" }}>
                                                    <span style={{ marginRight: "6px" }}>
                                                      {e.currency} {(e.anzahl * e.preis).toFixed(2)}
                                                    </span>
                                                    <span style={{ fontSize: "14px" }}>
                                                      <FontAwesomeIcon icon={faTrashAlt} color={"red"} style={{ cursor: "pointer" }} />
                                                    </span>
                                                  </td>
                                                </tr>
                                                <tr style={{}}>
                                                  <td colSpan={2} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
                                                    <span style={{}}>{e.hotelstrand}</span>
                                                  </td>
                                                </tr>
                                                {e.name !== "" ? (
                                                  <>
                                                    <tr style={{}}>
                                                      <td colSpan={2} style={{ paddingLeft: "4px" }}>
                                                        <span style={{ marginRight: "4px" }}>{e.name}</span>
                                                      </td>
                                                    </tr>
                                                  </>
                                                ) : null}
                                                <tr style={{}}>
                                                  <td colSpan={2} style={{ paddingLeft: "4px" }}>
                                                    <span style={{ marginRight: "4px" }}>
                                                      {e.bereich} {l("Reihe")} {e.reihe}
                                                    </span>
                                                  </td>
                                                </tr>
                                              </>
                                            ))}
                                          </>
                                        ))}
                                        <tr style={{ backgroundColor: "rgba(228, 233, 237, 1)", fontWeight: "bold", height: "36px" }}>
                                          <td colSpan={2} style={{ paddingLeft: "4px", textAlign: "right", paddingRight: "8px" }}>
                                            <span>{l("Gesamt")}: € 540.00</span>
                                            <br />
                                            <span style={{ fontWeight: "normal", fontSize: "12px", marginTop: "-10px" }}>{l("AllePreise")}</span>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px", maxWidth: "800px" }}>
                                      <Link to="/Hauptseite">
                                        <Button
                                          style={{ width: "70px" }}
                                          type={"primary"}
                                          /*onClick={() => {

                                  message.success(l("PlusWarenkorb"));
                                }}*/
                                        >
                                          <FontAwesomeIcon icon={faArrowLeft} size={"lg"} color={"white"} />
                                        </Button>
                                      </Link>
                                      <Button
                                        style={{ width: "70px" }}
                                        type={"danger"}
                                        /*onClick={() => {
                                    this.data.pickedDays.splice(0, this.data.pickedDays.length);
                                    this.data.currentstep = 0;
                                    this.props.onCancel();
                                  }}*/
                                      >
                                        <FontAwesomeIcon icon={faTrashAlt} size={"lg"} color={"white"} />
                                      </Button>
                                      <Button
                                        disabled={this.dataxy.length < 1}
                                        style={{ width: "70px" }}
                                        type={"primary"}
                                        onClick={() => {
                                          this.data.currentstep = 1;
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faArrowRight} size={"lg"} color={"white"} />
                                      </Button>
                                    </div>
                                  </div>
                                </Form>
                              </>
                            ) : this.data.currentstep === 1 && this.data.mybeachanmeldung === 0 ? (
                              <>
                                <Form layout={"vertical"}>
                                  <Row type={"flex"} gutter={32}>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                      <h3 style={{ textAlign: "center", color: "grey" }}>{l("LoginAuth")}</h3>
                                      <OAuth />
                                      {/*<StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} />*/}
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                      <Row type={"flex"} gutter={32}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                          <h3 style={{ textAlign: "center", color: "grey" }}>{l("LoginMyBeachCouch")}</h3>
                                          <Card style={{ width: "100%", backgroundColor: "transparent" }}>
                                            <div style={{ marginTop: "-12px" }}>
                                              <Form.Item label={l("EmailAdresse")}>
                                                <Input placeholder={l("EmailAdresse")} allowClear={true} />
                                              </Form.Item>
                                              <Form.Item label={l("Passwort")}>
                                                <Input placeholder={l("Passwort")} allowClear={true} />
                                              </Form.Item>
                                              <Form.Item>
                                                <span style={{ width: "100%" }}>
                                                  <Button
                                                    style={{ width: "100%", marginTop: "4px" }}
                                                    onClick={() => {
                                                      this.data.mybeachanmeldung = 2;
                                                    }}
                                                    type="primary"
                                                  >
                                                    {l("Login")}
                                                  </Button>
                                                </span>
                                              </Form.Item>
                                              <Form.Item>
                                                <span style={{ width: "100%" }}>
                                                  <Button
                                                    style={{ width: "100%", marginTop: "4px" }}
                                                    onClick={() => {
                                                      this.data.mybeachanmeldung = 1;
                                                    }}
                                                    type="primary"
                                                  >
                                                    {l("ButtonNewAccount")}
                                                  </Button>
                                                </span>
                                              </Form.Item>
                                              <Form.Item>
                                                <span style={{ width: "100%" }}>
                                                  <Button
                                                    style={{ width: "100%", marginTop: "4px" }}
                                                    onClick={() => {
                                                      this.data.ModalPasswort = true;
                                                    }}
                                                  >
                                                    {l("PasswortVergessen")}
                                                  </Button>
                                                </span>
                                              </Form.Item>
                                            </div>
                                          </Card>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Form>
                              </>
                            ) : this.data.currentstep === 1 && this.data.mybeachanmeldung === 1 ? (
                              <>
                                <Form layout={"vertical"}>
                                  <Row type={"flex"} gutter={32}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                      <h3 style={{ textAlign: "center", color: "grey" }}>{l("RegistrierungBeiMBC")}</h3>
                                    </Col>
                                  </Row>
                                  <div style={{ width: "100%", backgroundColor: "transparent", border: "1px solid rgba(0, 0, 0, 0.1)", padding: "12px" }}>
                                    <Row type={"flex"} gutter={32}>
                                      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                        <Form.Item label={l("Titel") + " *"}>
                                          <Select placeholder={l("Titel")} allowClear={true}>
                                            <Option value="Frau">{l("Frau")}</Option>
                                            <Option value="Herr">{l("Herr")}</Option>
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}></Col>
                                    </Row>
                                    <Row type={"flex"} gutter={32}>
                                      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                        <Form.Item label={l("VorName") + " *"}>
                                          <Input placeholder={l("VorName")} allowClear={true} />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                        <Form.Item label={l("NachName") + " *"}>
                                          <Input placeholder={l("NachName")} allowClear={true} />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                    <Row type={"flex"} gutter={32}>
                                      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                        <Form.Item label={l("EmailAdresse") + " *"}>
                                          <Input placeholder={l("EmailAdresse")} allowClear={true} />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                        <Form.Item label={l("Telefon")}>
                                          <Input placeholder={l("Telefon")} allowClear={true} />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                    <Row type={"flex"} gutter={32}>
                                      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                        <Form.Item label={l("PLZ")}>
                                          <Input placeholder={l("PLZ")} allowClear={true} />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                        <Form.Item label={l("Ort")}>
                                          <Input placeholder={l("Ort")} allowClear={true} />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                    <Row type={"flex"} gutter={32}>
                                      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                        <Form.Item label={l("StrasseNummer")}>
                                          <Input placeholder={l("StrasseNummer")} allowClear={true} />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                        <Form.Item>
                                          <CountrySelect label={l("Land")} value={"DE"} savename={"country"} />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                    <Row type={"flex"} gutter={32}>
                                      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}></Col>
                                      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <Form.Item>
                                          <Button
                                            style={{ width: "80px", float: "left", marginTop: "10px" }}
                                            onClick={() => {
                                              this.data.mybeachanmeldung = 0;
                                              //this.data.currentstep = 1;
                                            }}
                                            type="primary"
                                          >
                                            <FontAwesomeIcon icon={faArrowLeft} size={"lg"} color={"white"} />
                                          </Button>
                                          <Button
                                            style={{ width: "120px", float: "right", marginTop: "10px" }}
                                            onClick={() => {
                                              //this.data.mybeachanmeldung = 2;
                                            }}
                                            type="primary"
                                          >
                                            {l("Speichern")}
                                          </Button>
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  </div>
                                </Form>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </Box>
                    <Modal
                      closable={false}
                      visible={this.data.ModalPasswort}
                      footer={[
                        <Button key="back" onClick={() => (this.data.ModalPasswort = false)} type="primary">
                          {l("Schließen")}
                        </Button>
                      ]}
                    >
                      {l("NeuesPasswort")}
                      <Form.Item>
                        <Input placeholder={l("EmailAdresse")} allowClear={true} />
                      </Form.Item>
                      <Form.Item>
                        <span style={{ float: "right" }}>
                          <Button
                            onClick={() => {
                              this.data.mybeachanmeldung = 1;
                            }}
                            type="primary"
                          >
                            {l("SendNeuesPasswort")}
                          </Button>
                        </span>
                      </Form.Item>
                    </Modal>
                  </>
                )}
              </Observer>
            )}
          </LContext.Consumer>
        )}
      </MediaQuery>
    );
  }
}

export default withRouter(Warenkorb);

/*

<!-- The core Firebase JS SDK is always required and must be listed first -->
<script src="https://www.gstatic.com/firebasejs/7.3.0/firebase-app.js"></script>

<!-- TODO: Add SDKs for Firebase products that you want to use
https://firebase.google.com/docs/web/setup#available-libraries -->
    <script src="https://www.gstatic.com/firebasejs/7.3.0/firebase-analytics.js"></script>

    <script>
// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyDNCLuivzv7JPfEqmhrPwiwUQw5kzi96G0",
    authDomain: "beachsofa-1569408736471.firebaseapp.com",
    databaseURL: "https://beachsofa-1569408736471.firebaseio.com",
    projectId: "beachsofa-1569408736471",
    storageBucket: "beachsofa-1569408736471.appspot.com",
    messagingSenderId: "39943723916",
    appId: "1:39943723916:web:46f089abd6a3663d1ed4a9",
    measurementId: "G-H5K9GRB53Z"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
</script>*/
