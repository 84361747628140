// @ts-ignore
import countryList from "react-select-country-list";
import { Form, Input, Select, Spin } from "antd";
import React, { Component } from "react";
import { SelectProps } from "antd/es/select";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

interface OwnProps extends SelectProps {
  label?: any;
  savename: string;
  submitted?: boolean;
  value: any;
}

type Props = OwnProps;

@observer
class CountrySelect extends Component<Props> {
  @observable options: { value: string; label: string }[] = countryList().getData();
  searchdebouncer = new Subject();
  static defaultProps = {};

  constructor(props: any) {
    super(props);
    this.searchdebouncer.pipe(debounceTime(300)).subscribe(x => this.fetchUser(x as string));
  }

  fetchUser = (value: string) => {
    this.options = countryList()
      .getData()
      .filter((v: { value: string; label: string }) => v.label.toLowerCase().startsWith(value.toLowerCase()));
  };

  render() {
    return this.props.label !== undefined ? (
      <Form.Item label={this.props.label}>
        <Select
          allowClear={true}
          value={this.props.value[this.props.savename]}
          className={
            (this.props.submitted && this.props.value[this.props.savename] === "") || this.props.value[this.props.savename] === undefined ? "has-error" : ""
          }
          showSearch={true}
          onChange={(e: string) => {
            this.props.value[this.props.savename] = e;
          }}
          onSearch={e => this.searchdebouncer.next(e)}
          filterOption={false}
        >
          {this.options.map(v => (
            <Select.Option key={v.label} value={v.label}>
              {v.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    ) : (
      <Select
        allowClear={true}
        value={this.props.value[this.props.savename]}
        className={
          (this.props.submitted && this.props.value[this.props.savename] === "") || this.props.value[this.props.savename] === undefined ? "has-error" : ""
        }
        showSearch={true}
        onChange={(e: string) => {
          this.props.value[this.props.savename] = e;
        }}
        onSearch={e => this.searchdebouncer.next(e)}
        filterOption={false}
      >
        {this.options.map(v => (
          <Select.Option key={v.label} value={v.label}>
            {v.label}
          </Select.Option>
        ))}
      </Select>
    );
  }
}

export default CountrySelect;
