import React, { PureComponent, Component, FC } from "react";

import lscache from "lscache";

import { Button, Col, Icon, Popover, Row } from "antd";
import { FlagIcon, FlagIconCode } from "react-flag-kit";
import { $enum } from "ts-enum-util";
import { registerLocale } from "react-datepicker";
registerLocale("de-DE", require("date-fns/locale/de"));
registerLocale("en-GB", require("date-fns/locale/en-GB"));

const lObjekt = {
  Containerlist: {
    DE: "Container Liste",
    GB: "Container list"
  },
  manuelleSuche: {
    DE: "manuelle Suche",
    GB: "manual search"
  },
  Stammdaten: {
    DE: "Stammdaten",
    GB: "Zu übersetzen"
  },
  Test: {
    DE: "Test",
    GB: "Zu übersetzen"
  },
  SpracheWahl: {
    DE: "Sprache Wählen",
    GB: "Select Language"
  },
  Anmeldung: {
    DE: "Anmeldung",
    GB: "Registration"
  },
  ErweiterteSuche: {
    DE: "Erweiterte Suche",
    GB: "Advanced search"
  },
  Equipment: {
    DE: "Equipment",
    GB: "Equipment"
  },
  Area: {
    DE: "Area",
    GB: "Area"
  },
  AdressInfo: {
    DE: "Adresse",
    GB: "Address"
  },
  DatenKontrolle: {
    DE: "Datenkontrolle",
    GB: "Check data"
  },
  ZugangAdmin: {
    DE: "Prüfen und Senden",
    GB: "Check and send"
  },
  VorNachname: {
    DE: "Vor- und Nachname",
    GB: "First and last name"
  },
  VorName: {
    DE: "Vorname",
    GB: "First name"
  },
  NachName: {
    DE: "Nachname",
    GB: "Last name"
  },
  EmailAdresse: {
    DE: "E-Mail Adresse",
    GB: "EMail address"
  },
  RegBetreiber: {
    DE: "Firma (Hotelname / Strandname)",
    GB: "Firm (Hotelname / Beachname)"
  },
  Land: {
    DE: "Land",
    GB: "Country"
  },
  StrasseNummer: {
    DE: "Adresse",
    GB: "Address"
  },
  Ort: {
    DE: "Ort",
    GB: "City"
  },
  Telefon: {
    DE: "Telefon",
    GB: "Phone"
  },
  PLZ: {
    DE: "PLZ",
    GB: "ZIP"
  },
  WebAdresse: {
    DE: "Webadresse",
    GB: "Website Url"
  },
  Weiter: {
    DE: "Weiter",
    GB: "Next"
  },
  Okay: {
    DE: "Okay !",
    GB: "Okay !"
  },
  Zurück: {
    DE: "Zurück",
    GB: "Back"
  },
  Senden: {
    DE: "Senden",
    GB: "Send"
  },
  AnmStep0_1: {
    DE: "Geben Sie Ihren Gästen die Möglichkeit:",
    GB: "Bitte Daten prüfen Ü"
  },
  AnmStep0_2: {
    DE: "Strandkörbe , Strand- und Poolliegen",
    GB: "Bitte Daten prüfen Ü"
  },
  AnmStep0_3: {
    DE: "jederzeit online zu buchen.",
    GB: "Bitte Daten prüfen Ü"
  },
  BitteDatenChecken: {
    DE: "Alles korrekt? Dann senden.",
    GB: "All correct? Then send."
  },
  AnmStep2: {
    DE: "Bitte Daten prüfen",
    GB: "Bitte Daten prüfen Ü"
  },
  AnmStep2_1: {
    DE: "Bitte Daten prüfen",
    GB: "Bitte Daten prüfen Ü"
  },
  Kalk_Ü: {
    DE: "Berechnen Sie Ihren Gewinn",
    GB: "Berechnen Sie Ihren Gewinn"
  },
  Kalk_1: {
    DE: "Gesamt Liegen",
    GB: "Liegen Gesamt"
  },
  Kalk_2: {
    DE: "Mietliegen in %",
    GB: "Bitte Daten prüfen Ü"
  },
  Kalk_3: {
    DE: "Anzahl Mietliegen",
    GB: "Bitte Daten prüfen Ü"
  },
  Kalk_4: {
    DE: "Preis pro Mietliege",
    GB: "Bitte Daten prüfen Ü"
  },
  Kalk_5: {
    DE: "Kommision",
    GB: "Bitte Daten prüfen Ü"
  },
  Kalk_6: {
    DE: "Ihr Gewinn/Tag",
    GB: "Bitte Daten prüfen Ü"
  },
  Kalk_7: {
    DE: "Ihr Gewinn/Monat",
    GB: "Bitte Daten prüfen Ü"
  },
  Vorteile: {
    DE: "Vorteile",
    GB: "Vorteile"
  },
  AdminTool: {
    DE: "Admin Tool",
    GB: "Admin Tool"
  },
  Impressum: {
    DE: "Impressum",
    GB: "Impressum"
  },
  Kalkulator: {
    DE: "Kalkulator",
    GB: "Kalkulator"
  },
  CookieAnzeige: {
    DE: (
      <>
        Diese Website nutzt{" "}
        <a href={"/#/Datenschutzbestimmungen"} target="_blank">
          Cookies,{" "}
        </a>
        durch die weitere Nutzung der Website stimmen Sie der Verwendung von Cookies zu.
      </>
    ),
    GB: (
      <>
        This website uses{" "}
        <a href={"/#/Datenschutzbestimmungen"} target="_blank">
          cookies.{" "}
        </a>
        By continuing to use the website, you agree to its use.
      </>
    )
  },
  HSBuchung_Ü: {
    DE: (
      <>
        {/*<h4 style={{ textAlign: "center" }}>Sie wollen im Urlaub nicht um 6 Uhr aufstehen um sich einen Platz am Pool zu sichern!</h4>*/}
        <h2 style={{ textAlign: "center", color: "grey" }}> Reservieren Sie bei uns Ihre Pool- oder Strandliege</h2>
      </>
    ),
    GB: (
      <>
        {/*<h4 style={{ textAlign: "center" }}>Sie wollen im Urlaub nicht um 6 Uhr aufstehen um sich einen Platz am Pool zu sichern!</h4>*/}
        <h2 style={{ textAlign: "center", color: "grey" }}> Reservieren Sie bei uns Ihre Pool- oder Strandliege</h2>
      </>
    )
  },
  HSBuchung_Ü1: {
    DE: "Reservieren Sie Ihre Pool- oder Strandliege",
    GB: "Reserve your pool or beach couch"
  },
  HSSuche_Ü: {
    DE: "Suchen Sie nach Hotel, Strand, Ort, Land",
    GB: "Search by hotel, beach, city, country"
  },
  HSSuche_PH: {
    DE: "Hotel, Strand, Region, Ort, Land",
    GB: "Hotel, beach, region, city, country"
  },
  MO: {
    DE: "Mo",
    GB: "Mo"
  },
  DI: {
    DE: "Di",
    GB: "Tu"
  },
  MI: {
    DE: "Mi",
    GB: "We"
  },
  DO: {
    DE: "Do",
    GB: "Th"
  },
  FR: {
    DE: "Fr",
    GB: "Fr"
  },
  SA: {
    DE: "Sa",
    GB: "Sa"
  },
  SO: {
    DE: "So",
    GB: "Su"
  },
  SucheHotel: {
    DE: "Suche nach Hotel / Stränden / Ort",
    GB: "Search for Hotel / Beach / City"
  },
  AuswahlDatum: {
    DE: "Auswahl Datum",
    GB: "Choose date"
  },
  AuswahlLiegen: {
    DE: "Auswahl Liegen",
    GB: "Choose beach couch"
  },
  Buchung: {
    DE: "Buchung",
    GB: "Booking"
  },
  Löschen: {
    DE: "Löschen",
    GB: "Clear"
  },
  Anzahl: {
    DE: "Anzahl",
    GB: "Quantity"
  },
  PlusWarenkorb: {
    DE: "Zum Warenkorb hinzugefügt",
    GB: "Added to cart"
  },
  Warenkorb: {
    DE: "Warenkorb",
    GB: "Shopping cart"
  },
  Bereich: {
    DE: "Bereich",
    GB: "Area"
  },
  Reihe: {
    DE: "Reihe",
    GB: "Row"
  },
  WarenkorbKasse: {
    DE: "Kasse",
    GB: "Payment"
  },
  WarenkorbAnmeldung: {
    DE: "Anmeldung",
    GB: "Sign in"
  },
  Filter: {
    DE: "Filter",
    GB: "Filter"
  },
  Gesamt: {
    DE: "Gesamt",
    GB: "Total"
  },
  AllePreise: {
    DE: "alle Angaben in Euro, inkl. MwSt.",
    GB: "all prices in Euro, incl. VAT"
  },
  LoginMyBeachCouch: {
    DE: "oder Login mit MyBeachCouch",
    GB: "or sign in with MyBeachCouch"
  },
  LoginAuth: {
    DE: "Login / Registration mit",
    GB: "Sign in / registration with"
  },
  Passwort: {
    DE: "Passwort",
    GB: "Password"
  },
  Login: {
    DE: "Login",
    GB: "Sign in"
  },
  ButtonNewAccount: {
    DE: "Registrieren?",
    GB: "New account?"
  },
  PasswortVergessen: {
    DE: "Passwort vergessen?",
    GB: "Forgot password?"
  },
  Schließen: {
    DE: "Schließen",
    GB: "Close"
  },
  NeuesPasswort: {
    DE: "Sie haben Ihr Passwort vergessen? Bitte geben Sie Ihre Email Adresse ein um ein neues Passwort zu erhalten.",
    GB: "Forgot your account’s password? Enter your email address and we’ll send you a new password."
  },
  SendNeuesPasswort: {
    DE: "Neues Passwort erhalten",
    GB: "Request an new Password"
  },
  RegistrierungBeiMBC: {
    DE: "Registrierung bei MyBeachCouch",
    GB: "Registration at MyBeachCouch"
  },
  NStartseite: {
    DE: "Liegen Suche",
    GB: "Search lounger"
  },
  NMeineBuchungen: {
    DE: "Meine Buchungen",
    GB: "My bookings"
  },
  NMeinAccount: {
    DE: "Mein Account",
    GB: "My account"
  },
  NAnbieterSeite: {
    DE: "Anbieterseite",
    GB: "Provider site"
  },
  BuchungenOffen: {
    DE: "offene",
    GB: "open"
  },
  BuchungenVergangene: {
    DE: "vergangene",
    GB: "former"
  },
  BuchungenAlle: {
    DE: "alle",
    GB: "all"
  },
  BuchungsDetails: {
    DE: "Buchungs Details",
    GB: "Booking details"
  },
  Profil: {
    DE: "Profil",
    GB: "Profil"
  },
  Einstellungen: {
    DE: "Einstellungen",
    GB: "Settings"
  },
  Speichern: {
    DE: "Speichern",
    GB: "Save"
  },
  Titel: {
    DE: "Anrede",
    GB: "Titel"
  },
  Frau: {
    DE: "Frau",
    GB: "Mrs."
  },
  Herr: {
    DE: "Herr",
    GB: "Mr."
  },
  WeiterZurAnmeldung: {
    DE: "Weiter zur Anmeldung",
    GB: "Start now"
  },
  JetztSenden: {
    DE: "Alles korrekt? Jetzt Senden",
    GB: "All correct?? Send now"
  },
  DatenÄndern: {
    DE: "Daten ändern",
    GB: "Change data"
  },
  DatenGesendet: {
    DE: "Ihre Anmeldung wurde übermittelt",
    GB: "Your registration has been submitted"
  },
  AnmeldeAntwort_1: {
    DE: "Ihre Daten wurden erfolgreich an MyBeachCouch.com übermittelt. Sie haben hierzu eine Bestätigung an Ihre angegebene Adresse erhalten.",
    GB: "Ihre Daten wurden erfolgreich an MyBeachCouch.com übermittelt. Sie haben hierzu eine Bestätigung an Ihre angegebene Adresse erhalten."
  },
  AnmeldeAntwort_2: {
    DE: "Es erfolgt nun die Einrichtung Ihres individuellen Administrations Bereiches und Sie bekommen in Kürze Ihre Zugangsdaten.",
    GB: "Es erfolgt nun die Einrichtung Ihres individuellen Administrations Bereiches und Sie bekommen in Kürze Ihre Zugangsdaten."
  },
  IhrMBCTeam: {
    DE: "Ihr MyBeachCouch.com Team",
    GB: "Your MyBeachCouch.com Team"
  },
  AnmeldungNichtErfolgreich: {
    DE: "Anmeldung nicht erfolgreich, bitte prüfen Sie Ihre Email Adresse",
    GB: "Anmeldung nicht erfolgreich, bitte prüfen Sie Ihre Email Adresse"
  }
};

export const LContext = React.createContext({ l: (text: string) => text, currentl: "DE" });

const LContextChange = React.createContext((language: Languages) => {});

enum LanguagesEnum {
  DE = "DE",
  EN = "GB"
}

export type Languages = LanguagesEnum | keyof typeof LanguagesEnum;

interface OwnProps {}

type Props = OwnProps;

type State = Readonly<{}>;

class Languagechanger extends PureComponent<Props, State> {
  readonly state: State = {};
  static defaultProps = {};
  render() {
    return (
      <LContext.Consumer>
        {({ l, currentl }) => (
          <LContextChange.Consumer>
            {changeL => (
              <>
                <Popover
                  placement="bottom"
                  title={l("SpracheWahl")}
                  content={
                    <LSchangeContent
                      changeL={v => {
                        changeL(v);
                      }}
                    />
                  }
                  trigger={navigator.userAgent.indexOf("Safari") !== -1 && navigator.userAgent.indexOf("Chrome") <= -1 ? "click" : "focus"}
                >
                  <div tabIndex={1} style={{ display: "inline-block", cursor: "pointer" }}>
                    <FlagIcon code={currentl as FlagIconCode} />
                  </div>
                </Popover>
              </>
            )}
          </LContextChange.Consumer>
        )}
      </LContext.Consumer>
    );
  }
}

interface OwnPropsLSchangeContent {
  changeL: (language: Languages) => void;
}

type PropsLSchangeContent = OwnPropsLSchangeContent;

const LSchangeContent: FC<PropsLSchangeContent> = props => {
  return (
    <Row type="flex" justify={"start"}>
      {$enum(LanguagesEnum).map(v => (
        <Col key={v} span={6}>
          <div
            onClick={() => {
              props.changeL(v);
            }}
            style={{ cursor: "pointer" }}
          >
            <FlagIcon code={v as FlagIconCode} />
          </div>
        </Col>
      ))}
    </Row>
  );
};

interface OwnProps2 {}

type Props2 = OwnProps2;

type State2 = Readonly<{
  language: Languages;
}>;

export class Languageprovider extends PureComponent<Props2, State2> {
  readonly state: State2 = {
    language: (lscache.get("language") as Languages) || "DE"
  };

  l = (text: string): string => {
    if (lObjekt[text] !== undefined) {
      return lObjekt[text][this.state.language];
    } else return text + " --!Ü!";
  };

  changeLanguage = (l: Languages) => {
    lscache.set("language", l);
    this.setState({ language: l });
    window.setTimeout(() => {
      this.forceUpdate();
    }, 100);
  };

  render() {
    return (
      <LContext.Provider value={{ l: this.l, currentl: this.state.language }}>
        <LContextChange.Provider value={this.changeLanguage}>{this.props.children}</LContextChange.Provider>
      </LContext.Provider>
    );
  }
}

export default Languagechanger;
