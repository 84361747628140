import React, { Component } from "react";
import { Button, Icon, Layout, Popover, Row, Col, notification } from "antd";
import { Redirect, Route, RouteComponentProps, RouteProps, Switch, withRouter } from "react-router-dom";
import lscache from "lscache";
import Header from "./Framework/Header";
import HeaderAnbieter from "./Framework/HeaderAnbieter";
import Navi, { MenuItem } from "./Framework/Navi/Navi";
import Startseite from "./Sites/AnbieterSeite/Startseite";
import MainWebsite from "./Sites/BuchungsSeite/MainWebsite";
import Warenkorb from "./Sites/Warenkorb/Warenkorb";
import StartseiteLoggedin from "./Sites/StartseiteLoggedin";
import Languagechanger, { Languages, LContext } from "./Framework/Languagechanger/Languagechanger";
import Anmeldung from "./Sites/AnbieterSeite/Anmeldung";
import MeineBuchungen from "./Sites/MeineBuchungen/MeineBuchungen";
import MeinAccount from "./Sites/MeinAccount/MeinAccount";
import "./App.scss";
import Cookie from "./Framework/OurComps/Cookie";
import Datenschutz from "./Sites/Datenschutz";
import { observable } from "mobx";
import MediaQuery from "react-responsive";

class OrderItem {
  @observable bereich: number | "" = "";
  @observable reihe: number | "" = "";
  @observable einzelpreis: number = 0;
  @observable type: number = 0;
  @observable anzahl: number = 0;
  @observable datum: number = 0;
}

interface ProtectedRouteProps extends RouteProps {
  isAuthenticated: boolean;
  authenticationPath: string;
}
class ProtectedRoute extends Route<ProtectedRouteProps> {
  public render() {
    let redirectPath: string = "";
    if (!this.props.isAuthenticated) {
      redirectPath = this.props.authenticationPath;
    }

    if (redirectPath) {
      const renderComponent = () => <Redirect to={{ pathname: redirectPath }} />;
      return <Route {...this.props} component={renderComponent} render={undefined} />;
    } else {
      return <Route {...this.props} />;
    }
  }
}

interface OwnProps extends RouteComponentProps {}

type Props = OwnProps;

type State = Readonly<{
  loggedIn: boolean;
  user?: any;
}>;

class App extends Component<Props, State> {
  mousesuspend = () => {};
  readonly state: State = {
    loggedIn: true,
    user: lscache.get("user") || undefined
  };
  static defaultProps = {};

  componentDidMount(): void {
    if (lscache.get("loggedIn") === true) {
      this.mousesuspend = this.refreshChache.bind(this, lscache.get("user"));
      document.body.addEventListener("click", this.mousesuspend);
    }
  }

  login = (user: Object): void => {
    lscache.set("loggedIn", true, 10);
    lscache.set("user", user, 10);
    this.setState({ loggedIn: true, user });
    this.mousesuspend = this.refreshChache.bind(this, user);
    // @ts-ignore
    document.body.addEventListener("click", this.mousesuspend);
  };
  refreshChache = (user: any) => {
    if (!lscache.get("loggedIn")) {
      this.logout();
      return;
    }
    lscache.set("loggedIn", true, 1000);
    lscache.set("user", user, 1000);
  };
  logout = (): void => {
    document.body.removeEventListener("click", this.mousesuspend);
    lscache.remove("loggedIn");
    lscache.remove("user");
    this.setState({ loggedIn: false, user: undefined });
  };
  openNotification = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          lscache.set("cookiesAccepted", true);
          notification.close(key);
        }}
      >
        aKKZEPTIEREN
      </Button>
    );
    notification.open({
      message: "Cookies",
      placement: "bottomRight",
      description: "Accept cookies",
      duration: 0,
      btn,
      key,
      onClose: () => {
        this.forceUpdate();
      }
    });
  };
  render() {
    //lscache.set("cookiesAccepted", false);

    return (
      <MediaQuery minWidth={600}>
        {matches => (
          <LContext.Consumer>
            {({ l, currentl }) => (
              <>
                {this.props.location.pathname === "/Anmeldung" || this.props.location.pathname === "/Startseite" ? <HeaderAnbieter /> : <Header />}
                {this.props.location.pathname === "/" ||
                this.props.location.pathname === "/Datenschutzbestimmungen" ||
                this.props.location.pathname === "/Anmeldung" ||
                this.props.location.pathname === "/MeineBuchungen" ||
                this.props.location.pathname === "/Warenkorb" ||
                this.props.location.pathname === "/MeinAccount" ||
                this.props.location.pathname === "/Startseite" ||
                lscache.get("loggedIn") === undefined ? (
                  <Switch>
                    <Route exact path="/" render={props => <div></div>} />
                    <ProtectedRoute
                      isAuthenticated={this.state.loggedIn}
                      path="/Startseite"
                      render={() => <Startseite login={() => {}}></Startseite>}
                      authenticationPath={"/"}
                    />
                    <ProtectedRoute
                      isAuthenticated={this.state.loggedIn}
                      path="/Datenschutzbestimmungen"
                      render={() => <Datenschutz></Datenschutz>}
                      authenticationPath={"/"}
                    />
                    <ProtectedRoute isAuthenticated={this.state.loggedIn} path="/Anmeldung" render={() => <Anmeldung></Anmeldung>} authenticationPath={"/"} />
                    <ProtectedRoute
                      isAuthenticated={this.state.loggedIn}
                      path="/Dashboard"
                      render={() => <StartseiteLoggedin>Test</StartseiteLoggedin>}
                      authenticationPath={"/"}
                    />
                    <ProtectedRoute isAuthenticated={this.state.loggedIn} path="/registration" render={() => <Anmeldung />} authenticationPath={"/"} />
                    <ProtectedRoute
                      isAuthenticated={this.state.loggedIn}
                      path="/Hauptseite/:ID?"
                      render={() => <MainWebsite></MainWebsite>}
                      authenticationPath={"/"}
                    />
                    <ProtectedRoute
                      isAuthenticated={this.state.loggedIn}
                      path="/MeineBuchungen"
                      render={() => <MeineBuchungen></MeineBuchungen>}
                      authenticationPath={"/"}
                    />
                    <ProtectedRoute
                      isAuthenticated={this.state.loggedIn}
                      path="/MeinAccount"
                      render={() => <MeinAccount></MeinAccount>}
                      authenticationPath={"/"}
                    />
                    <ProtectedRoute isAuthenticated={this.state.loggedIn} path="/Warenkorb" render={() => <Warenkorb></Warenkorb>} authenticationPath={"/"} />
                    <Route
                      path="*"
                      render={props => {
                        if (!lscache.get("loggedin")) {
                          return <Redirect to="/" />;
                        } else return null;
                      }}
                    />
                  </Switch>
                ) : this.props.location.pathname.includes("/Hauptseite") ? (
                  <Switch>
                    <Route exact path="/" render={props => <div></div>} />
                    <ProtectedRoute
                      isAuthenticated={this.state.loggedIn}
                      path="/Startseite"
                      render={() => <Startseite login={() => {}}></Startseite>}
                      authenticationPath={"/"}
                    />
                    <ProtectedRoute
                      isAuthenticated={this.state.loggedIn}
                      path="/Dashboard"
                      render={() => <StartseiteLoggedin>Test</StartseiteLoggedin>}
                      authenticationPath={"/"}
                    />
                    <ProtectedRoute isAuthenticated={this.state.loggedIn} path="/registration" render={() => <Anmeldung />} authenticationPath={"/"} />
                    <ProtectedRoute
                      isAuthenticated={this.state.loggedIn}
                      path="/Hauptseite/:ID?"
                      render={() => <MainWebsite></MainWebsite>}
                      authenticationPath={"/"}
                    />
                    <ProtectedRoute
                      isAuthenticated={this.state.loggedIn}
                      path="/MeinAccount"
                      render={() => <MeineBuchungen></MeineBuchungen>}
                      authenticationPath={"/"}
                    />
                    <ProtectedRoute
                      isAuthenticated={this.state.loggedIn}
                      path="/MeineBuchungen"
                      render={() => <MeinAccount></MeinAccount>}
                      authenticationPath={"/"}
                    />
                    <ProtectedRoute isAuthenticated={this.state.loggedIn} path="/Warenkorb" render={() => <Warenkorb></Warenkorb>} authenticationPath={"/"} />
                    <Route
                      path="*"
                      render={props => {
                        if (!lscache.get("loggedin")) {
                          return <Redirect to="/" />;
                        } else return null;
                      }}
                    />
                  </Switch>
                ) : (
                  <Layout>
                    <Layout.Header
                      style={{
                        width: "100%",
                        position: "fixed",
                        height: "60px",
                        backgroundColor: "white",
                        borderBottom: "1px solid lightgrey"
                      }}
                    >
                      <Row>
                        <Col span={8}>
                          <Button onClick={this.logout}>Logout</Button>
                        </Col>
                        <Col span={8} style={{ textAlign: "center" }}>
                          Select Feld Hotelwechsel
                        </Col>
                        <Col span={8} style={{ textAlign: "right", paddingRight: 30 }}>
                          <span style={{ paddingRight: 20 }}>{this.state.user === undefined ? "" : this.state.user.name}</span>
                          <Languagechanger />
                        </Col>
                      </Row>
                    </Layout.Header>
                    <Layout>
                      <Navi>
                        {/*                <SubMenu title={"tets"} icon={<Myicon name={"logo"}>asdads</Myicon>}>
                  <MenuItem key="/" path={"/"} title={"inner"} />
                </SubMenu>*/}
                        <MenuItem key="/Dashboard" path={"/Dashboard"} title={"Dashboard"} icon={<Icon type={"dashboard"} />} />
                        <MenuItem key="/Registration" path={"/Registration"} title={l("Stammdaten")} />
                      </Navi>
                      <Layout className={"backgroundtrans"} style={{ marginLeft: 200, marginTop: "60px" }}>
                        <Layout.Content>
                          <Switch>
                            <Route exact path="/" render={props => <div></div>} />
                            <ProtectedRoute
                              isAuthenticated={this.state.loggedIn}
                              path="/Startseite"
                              render={() => <Startseite login={() => {}}></Startseite>}
                              authenticationPath={"/"}
                            />
                            <ProtectedRoute
                              isAuthenticated={this.state.loggedIn}
                              path="/Datenschutzbestimmungen"
                              render={() => <Datenschutz></Datenschutz>}
                              authenticationPath={"/"}
                            />
                            <ProtectedRoute
                              isAuthenticated={this.state.loggedIn}
                              path="/Dashboard"
                              render={() => <StartseiteLoggedin>Test</StartseiteLoggedin>}
                              authenticationPath={"/"}
                            />
                            <ProtectedRoute isAuthenticated={this.state.loggedIn} path="/registration" render={() => <Anmeldung />} authenticationPath={"/"} />
                            <ProtectedRoute
                              isAuthenticated={this.state.loggedIn}
                              path="/Hauptseite/:ID?"
                              render={() => <MainWebsite></MainWebsite>}
                              authenticationPath={"/"}
                            />
                            <Route
                              path="*"
                              render={props => {
                                if (!lscache.get("loggedin")) {
                                  return <Redirect to="/" />;
                                } else return null;
                              }}
                            />
                          </Switch>
                        </Layout.Content>
                      </Layout>
                    </Layout>
                  </Layout>
                )}
                {matches ? (
                  <footer
                    id={"Impressum"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "60px",
                      marginTop: "20px",
                      width: "100%",
                      backgroundColor: "#1890ff",
                      overflow: "hidden"
                    }}
                  >
                    <div style={{ backgroundColor: "#1890ff", paddingTop: "0px", textAlign: "center", paddingLeft: "20px", paddingRight: "20px" }}>
                      <Row type={"flex"} gutter={32}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ lineHeight: "54px" }}>
                          <span style={{ color: "white", paddingRight: "20px" }}>Impressum </span>
                          <span style={{ color: "white", paddingRight: "20px" }}>Datenschutzbestimmungen </span>
                          <span style={{ color: "white", paddingRight: "20px", whiteSpace: "nowrap" }}>
                            @{new Date().getFullYear()} Copyright MyBeachCouch.com
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </footer>
                ) : (
                  <footer
                    id={"Impressum"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "60px",
                      marginTop: "20px",
                      width: "100%",
                      backgroundColor: "#1890ff",
                      overflow: "hidden"
                    }}
                  >
                    <div style={{ backgroundColor: "#1890ff", paddingTop: "0px", textAlign: "center", paddingLeft: "20px", paddingRight: "20px" }}>
                      <Row type={"flex"} gutter={32}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ lineHeight: "26px" }}>
                          <span style={{ color: "white", paddingRight: "20px" }}>Impressum </span>
                          <span style={{ color: "white", paddingRight: "20px" }}>Datenschutzbestimmungen </span>
                          <span style={{ color: "white", paddingRight: "20px", whiteSpace: "nowrap" }}>
                            @{new Date().getFullYear()} Copyright MyBeachCouch.com
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </footer>
                )}
                <Cookie />
              </>
            )}
          </LContext.Consumer>
        )}
      </MediaQuery>
    );
  }
}

export default withRouter(App);
