import React, { Component } from "react";
import { LContext } from "../Framework/Languagechanger/Languagechanger";
interface OwnProps {}

type Props = OwnProps;

type State = Readonly<{}>;

class StartseiteLoggedin extends Component<Props, State> {
  readonly state: State = {};
  static defaultProps = {};
  render() {
    return <LContext.Consumer>{({ l, currentl }) => <>{l("Test")}</>}</LContext.Consumer>;
  }
}

export default StartseiteLoggedin;
