import React, { Component, FC } from "react";
import Languagechanger, { LContext } from "../../Framework/Languagechanger/Languagechanger";
import Buchung from "../BuchungsSeite/Buchung";
import { Button, Col, Drawer, Icon, Input, Layout, Row, Modal, Tooltip, Form, Tabs, Calendar, Badge, Select, AutoComplete, Spin, Card } from "antd";
import { HashLink as AnchorLink } from "react-router-hash-link";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import Box from "../../Framework/Box";
import "react-datepicker/dist/react-datepicker.css";
import Myicon from "../../Icons/Myicon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import axios, { AxiosRequestConfig } from "axios";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { observer, Observer } from "mobx-react";
import { observable } from "mobx";
import { faUserCheck, faCog } from "@fortawesome/free-solid-svg-icons";
import CountrySelect from "../../Framework/CountrySelect/CountrySelect";

const { TabPane } = Tabs;
const { Option } = Select;

interface OwnProps extends RouteComponentProps {}

type Props = OwnProps;

interface IUser {
  HotelStrandName: string;
  VorNachname: string;
  VorName: string;
  EmailAdresse: string;
  RegBetreiber: string;
  Land: string;
  StrasseNummer: string;
  Ort: string;
  Telefon: string;
}

type State = Readonly<{
  navopen: boolean;
  selectedTab: string;
  dataSource: any[];
  data: IUser;
}>;
@observer
class MeinAccount extends Component<Props, State> {
  readonly state: State = {
    navopen: false,
    selectedTab: "1",
    dataSource: [],
    data: {
      HotelStrandName: "",
      VorNachname: "",
      VorName: "",
      EmailAdresse: "",
      RegBetreiber: "",
      Land: "",
      StrasseNummer: "",
      Ort: "",
      Telefon: ""
    }
  };

  render() {
    // @ts-ignore
    return (
      <LContext.Consumer>
        {({ l, currentl }) => (
          <Observer>
            {() => (
              <>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "45px", overflow: "hidden", minHeight: "calc(100vh - 125px)" }}>
                  <div style={{ backgroundColor: "transparent", display: "inline-block", width: "94%" }}>
                    <Box>
                      <Row type={"flex"}>
                        <Col
                          xs={{ span: 24, offset: 0 }}
                          sm={{ span: 24, offset: 0 }}
                          md={{ span: 20, offset: 2 }}
                          lg={{ span: 16, offset: 4 }}
                          xl={{ span: 12, offset: 6 }}
                          xxl={{ span: 10, offset: 7 }}
                          style={{ textAlign: "center" }}
                        >
                          <div style={{ width: "100%", display: "inline-block" }}>
                            <div style={{ textAlign: "center" }}>
                              <h2>{l("NMeinAccount")}</h2>
                            </div>
                          </div>
                          <div style={{ width: "100%", display: "inline-block" }}>
                            <div style={{ float: "left", textAlign: "left", width: "100%" }}>
                              <div className="card-container">
                                <Tabs onChange={(v: string) => this.setState({ selectedTab: v })} activeKey={this.state.selectedTab} type="card">
                                  <TabPane
                                    tab={
                                      <span>
                                        <FontAwesomeIcon icon={faUserCheck} size={"lg"} color={"black"} />
                                        <span style={{ marginLeft: "6px" }}>{l("Profil")}</span>
                                      </span>
                                    }
                                    key="1"
                                    style={{}}
                                  >
                                    <p style={{ width: "100%", backgroundColor: "#f0f2f5", padding: "4px", color: "black", fontWeight: "bold" }}>
                                      Sven Klemenz
                                    </p>
                                    <Form layout={"vertical"} style={{ marginLeft: "10px", marginRight: "10px", marginTop: "10px" }}>
                                      <Row type={"flex"} gutter={32}>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                          <Form.Item label={l("Titel") + " *"}>
                                            <Select placeholder={l("Titel")} allowClear={true}>
                                              <Option value="Frau">{l("Frau")}</Option>
                                              <Option value="Herr">{l("Herr")}</Option>
                                            </Select>
                                          </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}></Col>
                                      </Row>
                                      <Row type={"flex"} gutter={32}>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                          <Form.Item label={l("VorName") + " *"}>
                                            <Input placeholder={l("VorName")} allowClear={true} />
                                          </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                          <Form.Item label={l("NachName") + " *"}>
                                            <Input placeholder={l("NachName")} allowClear={true} />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                      <Row type={"flex"} gutter={32}>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                          <Form.Item label={l("EmailAdresse") + " *"}>
                                            <Input placeholder={l("EmailAdresse")} allowClear={true} />
                                          </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                          <Form.Item label={l("Telefon")}>
                                            <Input placeholder={l("Telefon")} allowClear={true} />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                      <Row type={"flex"} gutter={32}>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                          <Form.Item label={l("PLZ")}>
                                            <Input placeholder={l("PLZ")} allowClear={true} />
                                          </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                          <Form.Item label={l("Ort")}>
                                            <Input placeholder={l("Ort")} allowClear={true} />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                      <Row type={"flex"} gutter={32}>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                          <Form.Item label={l("StrasseNummer")}>
                                            <Input placeholder={l("StrasseNummer")} allowClear={true} />
                                          </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                          <Form.Item>
                                            <CountrySelect label={l("Land")} value={"DE"} savename={"country"} />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                      <Row type={"flex"} gutter={32}>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}></Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                          <Form.Item>
                                            <Button
                                              style={{ width: "120px", float: "right", marginTop: "10px" }}
                                              onClick={() => {
                                                //this.data.mybeachanmeldung = 2;
                                              }}
                                              type="primary"
                                            >
                                              {l("Speichern")}
                                            </Button>
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    </Form>
                                    <p style={{ width: "100%", backgroundColor: "#f0f2f5", padding: "4px", color: "black", fontWeight: "bold" }}>
                                      Soziale Netzwerke
                                    </p>
                                  </TabPane>
                                  <TabPane
                                    tab={
                                      <span>
                                        <FontAwesomeIcon icon={faCog} size={"lg"} color={"black"} />
                                        <span style={{ marginLeft: "6px" }}>{l("Einstellungen")}</span>
                                      </span>
                                    }
                                    key="2"
                                  >
                                    <Buchung onCancel={() => this.setState({ selectedTab: "1" })} />
                                  </TabPane>
                                </Tabs>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Box>
                  </div>
                </div>
              </>
            )}
          </Observer>
        )}
      </LContext.Consumer>
    );
  }
}

export default withRouter(MeinAccount);
