import React, { Component, FC } from "react";
import { Button, Layout, Icon, Row, Col, Drawer, Form, Input, Carousel, Card, Select, Modal } from "antd";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { inject, observer, Observer } from "mobx-react";
import Languagechanger, { LContext } from "../../Framework/Languagechanger/Languagechanger";
import Anmeldung from "./Anmeldung";
import { NavScroll, NavScrollItem } from "../../NavScroll/NavScroll";
import Box from "../../Framework/Box";
import handy from "../../Images/handy.jpg";
import { computed, observable } from "mobx";
import axios from "axios";
import { faClipboardList, faCalculator, faSearch, faArrowLeft, faArrowRight, faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faAddressCard, faEdit, faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toMoneyFormat, fromMoneyFormat } from "../../Framework/Helperfunctions/Formatter";
import { OurFontAwsomeIcon } from "../../Framework/OurComps/Icons/OurFontAwsomeIcon";
import moment from "moment";
import Myicon from "../../Icons/Myicon";
import CountrySelect from "../../Framework/CountrySelect/CountrySelect";

const { Option, OptGroup } = Select;

interface OwnProps extends RouteComponentProps {
  login: (user: Object) => void;
}

type Props = OwnProps;

type State = Readonly<{
  navopen: boolean;
  ModalAnmInfo: boolean;
}>;

const yearanzeige = new Date().getFullYear();

class Startseite extends Component<Props, State> {
  readonly state: State = {
    navopen: false,
    ModalAnmInfo: false
  };
  static defaultProps = {};
  // eslint-disable-next-line no-undef

  login = (): void => {
    this.props.login({ name: "hans" });
    this.props.history.push("/Dashboard");
  };

  render() {
    return (
      <LContext.Consumer>
        {({ l, currentl }) => (
          <>
            {/*{l("Test")}
            <Button onClick={this.login}>Login</Button>*/}
            <div style={{ display: "flex", justifyContent: "center", marginTop: "45px", overflow: "hidden", minHeight: "calc(100vh - 125px)" }}>
              <div style={{ backgroundColor: "transparent", display: "inline-block", width: "94%", maxWidth: "1200px" }}>
                <Box>
                  <h2 id={"Vorteile"} style={{ color: "dimgray", textAlign: "center", backgroundImage: "" }}>
                    Sie wollen Ihren Gästen die Möglichkeit geben, Ihre
                    <span style={{ color: "#1890ff" }}> Pool-, Strandliegen oder Strandkörbe</span> jederzeit online zu reservieren ?
                  </h2>
                  <div style={{ backgroundColor: "transparent", border: "1px solid rgba(0, 0, 0, 0.15)" }}>
                    <Row type={"flex"} gutter={32}>
                      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                        <Card style={{ width: "100%", backgroundColor: "transparent", marginTop: "-4px", border: "none" }}>
                          <p style={{ marginTop: "-8px" }}>
                            <Icon type="check-circle" style={{ paddingRight: 4, color: "green" }} />
                            Jederzeit schnell buchen
                          </p>
                          <p style={{ marginTop: "-3px" }}>
                            <Icon type="check-circle" style={{ paddingRight: 4, color: "green" }} />
                            Verfügbarkeiten täglich anpassbar
                          </p>
                          <p style={{ marginTop: "-3px" }}>
                            <Icon type="check-circle" style={{ paddingRight: 4, color: "green" }} />
                            Komfortable Administration
                          </p>
                          <p style={{ marginTop: "-3px", marginBottom: "-8px" }}>
                            <Icon type="check-circle" style={{ paddingRight: 4, color: "green" }} />
                            Kosten nur bei Buchung
                          </p>
                        </Card>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                        <Card style={{ width: "100%", backgroundColor: "transparent", marginTop: "-4px", border: "none" }}>
                          <p style={{ marginTop: "-8px", fontWeight: "bold" }}>
                            Melden Sie sich jetzt an:
                            <span style={{ marginLeft: "10px", cursor: "pointer" }} onClick={() => this.setState({ ModalAnmInfo: true })}>
                              <FontAwesomeIcon icon={faInfoCircle} size={"lg"} color={"#1890ff"} />
                            </span>
                          </p>
                          <p style={{ marginTop: "-8px" }}>und profitieren Sie täglich durch bisher ungenutzte Ressourcen </p>
                          <p style={{ marginBottom: "4px" }}>
                            <Form.Item>
                              {/* <Button
                              style={{ width: "80px", float: "left", marginTop: "10px" }}
                              onClick={() => {
                                //this.data.mybeachanmeldung = 0;
                                //this.data.currentstep = 1;
                              }}
                              type="primary"
                            >
                              <FontAwesomeIcon icon={faArrowLeft} size={"lg"} color={"white"} />
                            </Button>*/}
                              <Link to="./Anmeldung">
                                <Button
                                  style={{ width: "100%", float: "right", marginTop: "10px" }}
                                  onClick={() => {
                                    //this.data.mybeachanmeldung = 2;
                                  }}
                                  type="primary"
                                >
                                  {l("WeiterZurAnmeldung")}
                                  <span style={{ marginLeft: "10px" }}>
                                    <FontAwesomeIcon icon={faArrowRight} size={"lg"} color={"white"} />
                                  </span>
                                </Button>
                              </Link>
                            </Form.Item>
                          </p>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </Box>
                <Kalkulator id={"Kalkulator"} />
                <Box>
                  <h2 id={"Vorteile"}>{l("Vorteil")}</h2>
                  <Row type={"flex"} gutter={32}>
                    <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={12}>
                      <p>
                        <Icon type="check-circle" style={{ paddingRight: 4, color: "green" }} />
                        {l("Sie vergeben Ihre Anzahl von VIP Liegen pro Pool / Strand / Bereich")}
                      </p>
                      <p>
                        <Icon type="check-circle" style={{ paddingRight: 4, color: "green" }} />
                        {l("Sie legen Ihre Preise fest pro Pool / Strand / Bereich")}
                      </p>
                      <p>
                        <Icon type="check-circle" style={{ paddingRight: 4, color: "green" }} />
                        {l("Die Anzahl der Liegen und Preise können Sie saisonial ändern")}
                      </p>
                      <p>
                        <Icon type="check-circle" style={{ paddingRight: 4, color: "green" }} />
                        {l("Ihre Gäste können JEDERZEIT buchen")}
                      </p>
                      <p>
                        <Icon type="check-circle" style={{ paddingRight: 4, color: "green" }} />
                        {l("Kosten entstehen nur bei Buchung")}
                      </p>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={12}>
                      <p>
                        <img src={handy} style={{ width: "160px", borderRadius: "4px" }} />
                      </p>
                    </Col>
                  </Row>
                </Box>
                <Box>
                  <h2>{l("Administration")}</h2>
                  <p>
                    Hier Grafiken sichtweise Adminstrationsoberfläche
                    <br />
                  </p>
                </Box>
              </div>
            </div>
            <Modal
              /*style={{ marginTop: "-84px" }}*/
              title="Information"
              closeIcon={[
                <span onClick={() => this.setState({ ModalAnmInfo: false })}>
                  <FontAwesomeIcon icon={faTimes} size={"lg"} />
                </span>
              ]}
              visible={this.state.ModalAnmInfo}
              /*footer={null}*/
              footer={[
                <Button key="back" onClick={() => this.setState({ ModalAnmInfo: false })} type="primary">
                  {l("Schließen")}
                </Button>
              ]}
            >
              teset
            </Modal>
          </>
        )}
      </LContext.Consumer>
    );
  }
}

class calcData {
  @observable _liegenGes: number = 140;
  get liegenGes() {
    return this._liegenGes;
  }
  set liegenGes(value: number) {
    if (value < this.liegenVIP) {
      this._liegenVIP = value;
    }
    this._liegenGes = value;
  }
  @observable _liegenVIP: number = 14;
  get liegenVIP() {
    return this._liegenVIP;
  }
  set liegenVIP(value: number) {
    if (value > this.liegenGes) {
      this._liegenVIP = this.liegenGes;
    } else this._liegenVIP = value;
  }

  @observable preis: string = "10.00";
  @computed get realPreis(): number {
    return parseFloat(this.preis) || 0;
  }

  kommission: number = 10.0;
  @computed get gTag() {
    return toMoneyFormat(this.liegenVIP * this.realPreis * (1 - this.kommission / 100));
  }
  @computed get gMonat() {
    return toMoneyFormat(fromMoneyFormat(this.gTag) * 30);
  }
  @computed get liegenVIPProzent() {
    return parseFloat(((this.liegenVIP / this.liegenGes) * 100).toFixed(2)) || 0;
  }
  set liegenVIPProzent(value: number) {
    if (value > 100) {
      this.liegenVIP = this.liegenGes;
    } else this.liegenVIP = (this.liegenGes * value) / 100;
  }
}

interface OwnPropsKalkulator {
  id?: string;
}

type PropsKalkulator = OwnPropsKalkulator;

type StateKalkulator = Readonly<{}>;

@observer
class Kalkulator extends Component<PropsKalkulator> {
  static defaultProps = {
    id: ""
  };
  data: calcData;
  constructor(props: any) {
    super(props);
    this.data = new calcData();
  }
  render() {
    return (
      <LContext.Consumer>
        {({ l, currentl }) => (
          <Observer>
            {() => (
              <Box id={this.props.id}>
                <Form layout={"inline"}>
                  <Row type={"flex"} gutter={32}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                      <h2>Kalkulator</h2>
                      <p>Berechnen Sie Ihren Gewinn, nach Anzahl ihrer buchbaren Liegen:</p>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                      <div
                        style={{
                          width: "100%",
                          maxWidth: "500px",
                          backgroundColor: "darkorange",
                          borderRadius: "4px",
                          marginTop: "16px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "4px"
                        }}
                      >
                        <table
                          style={{
                            width: "100%",
                            color: "white"
                          }}
                        >
                          <tbody>
                            <tr style={{ padding: "4px", borderBottom: "1px solid white" }}>
                              <td colSpan={2}>
                                <h3 style={{ color: "white" }}>{l("Kalk_Ü")}</h3>
                              </td>
                            </tr>
                            <tr style={{ padding: "4px", borderBottom: "1px dashed white" }}>
                              <td style={{ padding: "4px" }}>{l("Kalk_1")}</td>
                              <td style={{ padding: "4px" }}>
                                <Input
                                  allowClear={true}
                                  value={this.data.liegenGes}
                                  onChange={e => {
                                    this.data.liegenGes = parseInt(e.target.value) || 0;
                                  }}
                                  style={{ textAlign: "right", maxWidth: "140px", float: "right" }}
                                />
                              </td>
                            </tr>
                            <tr style={{ padding: "4px", borderBottom: "1px dashed white" }}>
                              <td style={{ padding: "4px" }}>{l("Kalk_2")}</td>
                              <td style={{ padding: "4px" }}>
                                <Input
                                  allowClear={true}
                                  value={this.data.liegenVIPProzent}
                                  onChange={e => (this.data.liegenVIPProzent = parseInt(e.target.value) || 0)}
                                  style={{ textAlign: "right", maxWidth: "140px", float: "right" }}
                                />
                              </td>
                            </tr>
                            <tr style={{ padding: "4px", borderBottom: "1px dashed white" }}>
                              <td style={{ padding: "4px" }}>{l("Kalk_3")}</td>
                              <td style={{ padding: "4px" }}>
                                <Input
                                  allowClear={true}
                                  value={this.data.liegenVIP}
                                  onChange={e => (this.data.liegenVIP = parseInt(e.target.value) || 0)}
                                  style={{ textAlign: "right", maxWidth: "140px", float: "right" }}
                                />
                              </td>
                            </tr>
                            <tr style={{ padding: "4px", borderBottom: "1px dashed white" }}>
                              <td style={{ padding: "4px" }}>{l("Kalk_4")}</td>
                              <td style={{ padding: "4px" }}>
                                <Input
                                  allowClear={true}
                                  value={this.data.preis}
                                  onChange={e => (this.data.preis = e.target.value)}
                                  style={{ textAlign: "right", maxWidth: "140px", float: "right" }}
                                />
                              </td>
                            </tr>
                            <tr style={{ padding: "4px", borderBottom: "1px dashed white" }}>
                              <td style={{ padding: "4px" }}>{l("Kalk_5")}</td>
                              <td style={{ padding: "4px", textAlign: "right" }}>{this.data.kommission} %</td>
                            </tr>
                            <tr style={{ padding: "4px", borderBottom: "1px solid white" }}>
                              <td style={{ padding: "4px" }}>{l("Kalk_6")}</td>
                              <td style={{ padding: "4px", textAlign: "right" }}>{this.data.gTag}</td>
                            </tr>
                            <tr style={{ padding: "4px" }}>
                              <td style={{ padding: "4px", fontWeight: "bold", lineHeight: "40px", minWidth: "130px" }}>
                                <h4 style={{ color: "white" }}>{l("Kalk_7")}</h4>
                              </td>
                              <td style={{ padding: "4px", fontWeight: "bold", textAlign: "right", float: "right" }}>
                                <h4 style={{ color: "white", borderBottom: "3px double white" }}>{this.data.gMonat}</h4>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Box>
            )}
          </Observer>
        )}
      </LContext.Consumer>
    );
  }
}

export default withRouter(Startseite);
