import React, { FC } from "react";
function importAll(r: any) {
  let images = {};
  r.keys().map((item: any) => {
    // @ts-ignore
    let newname = item.replace("./", "");
    // @ts-ignore
    images[newname.replace(/\.(png|jpe?g|svg)$/, "")] = r(item);
  });
  return images;
}
const images: Object = importAll(require.context("./images", false, /\.(png|jpe?g|svg)$/));

export interface OwnProps {
  height?: string;
  // Hier bitte die Namen aller zur verfügung stehenden Optionen eintragen
  name: "logo" | "logointech" | "pool_1" | "liege1" | "handy" | "Bild1";
}

type Props = OwnProps;

const Myicon: FC<Props> = props => {
  // @ts-ignore
  //return <img height={props.height || "20px"} src={images[props.name]} />;
  return (
    <div style={{ overflowWrap: "break-word", overflow: "auto" }}>
      {props.height !== "" ? (
        <img height={props.height} src={images[props.name]} />
      ) : (
        <img style={{ maxWidth: "100%", height: "auto" }} src={images[props.name]} />
      )}
    </div>
  );
};

export default Myicon;
