import React, { PureComponent, FC } from "react";
import lscache from "lscache";
import { Button } from "antd";
import { LContext } from "../Languagechanger/Languagechanger";

interface OwnPropsCookie {}

type PropsCookie = OwnPropsCookie;
type StateCookie = Readonly<{
  loggedIn: boolean;
  user?: any;
}>;

class Cookie extends PureComponent<PropsCookie, StateCookie> {
  render() {
    return (
      <LContext.Consumer>
        {({ l, currentl }) => (
          <>
            {!lscache.get("cookiesAccepted") ? (
              <div
                style={{
                  position: "fixed",
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "center",
                  bottom: 0,
                  width: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  paddingRight: "10px",
                  paddingLeft: "10px",
                  //paddingTop: "10px",
                  lineHeight: "60px",
                  boxSizing: "border-box",
                  maxHeight: "180px",
                  minHeight: "60px"
                }}
              >
                <div style={{ lineHeight: "18px", color: "white", textAlign: "center" }} className="cookiepadding">
                  {l("CookieAnzeige")}
                </div>
                <div>
                  <Button
                    type={"primary"}
                    style={{ marginLeft: "20px" }}
                    onClick={() => {
                      lscache.set("cookiesAccepted", true);
                      this.forceUpdate();
                    }}
                  >
                    {l("Okay")}
                  </Button>
                </div>
              </div>
            ) : null}
          </>
        )}
      </LContext.Consumer>
    );
  }
}
export default Cookie;
