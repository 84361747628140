import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "antd/dist/antd.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { HashRouter } from "react-router-dom";
import { Languageprovider } from "./Framework/Languagechanger/Languagechanger";
import { LoadScriptNext } from "@react-google-maps/api";
//googleMapsApiKey="AIzaSyA2T5XThMVo2UZakOVJ9P4RuJkeg43YKjc"
ReactDOM.render(
  <Languageprovider>
    <HashRouter>
      <LoadScriptNext id="script-loader" libraries={["drawing"]}>
        <App />
      </LoadScriptNext>
    </HashRouter>
  </Languageprovider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
