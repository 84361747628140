import React, { Component, FC } from "react";
import Languagechanger, { LContext } from "../Framework/Languagechanger/Languagechanger";
import { Button, Col, Drawer, Icon, Layout, Row } from "antd";
import { HashLink as AnchorLink } from "react-router-hash-link";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./NavScroll.scss";
interface OwnPropsDrawerMenuNavItem extends RouteComponentProps {
  onClick?: () => void;
  topath?: string;
  scroll?: boolean;
}

type PropsDrawerMenuNavItem = OwnPropsDrawerMenuNavItem;

const DrawerMenuNavIteminner: FC<PropsDrawerMenuNavItem> = ({ onClick, topath, scroll = true, location, children }) => {
  return (
    <>
      <AnchorLink
        scroll={el => {
          //el.scrollIntoView({ behavior: "smooth", block: "start" });
          window.scrollTo({ left: 0, top: el.offsetTop - 55, behavior: "smooth" });
        }}
        to={(scroll ? "#" : "") + (topath !== undefined ? topath : "")}
      >
        <div onClick={onClick} className={"DrawerMenuNavItem"}>
          {children}
        </div>
      </AnchorLink>
    </>
  );
};

export const NavScrollItem = withRouter(DrawerMenuNavIteminner);

interface zeroProps {
  navOpen: boolean;
  onNavClose: () => void;
}

export const NavScroll: FC<zeroProps> = props => {
  return (
    <Drawer
      className={"DrawerMenu"}
      placement={"left"}
      closable={false}
      onClose={() => {
        props.onNavClose();
      }}
      visible={props.navOpen}
    >
      {props.children}
    </Drawer>
  );
};
