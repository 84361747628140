import React, { Component, FC } from "react";
import firebase from "firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { observer, Observer } from "mobx-react";
import { LContext } from "../Languagechanger/Languagechanger";

interface OwnProps {
  // isSignedIn: boolean;
}

type Props = OwnProps;

type State = Readonly<{
  isSignedIn: boolean;
}>;

firebase.initializeApp({
  apiKey: "AIzaSyDNCLuivzv7JPfEqmhrPwiwUQw5kzi96G0",
  authDomain: "beachsofa-1569408736471.firebaseapp.com"
});

@observer
class OAuth extends Component<Props, State> {
  state: State = {
    //polygons: []
    isSignedIn: false
  };
  //static defaultProps = {};

  uiConfig = {
    signInFlow: "popup",
    //languageCode: "DE",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.TwitterAuthProvider.PROVIDER_ID
      //firebase.auth.GithubAuthProvider.PROVIDER_ID,
      //firebase.auth.EmailAuthProvider.PROVIDER_ID
    ],
    //signInSuccessUrl: "/#/Warenkorb"
    callbacks: {
      signInSuccessWithAuthResult: (result: any, redirectUrl: any) => {
        //console.log(result);
        console.log(result.additionalUserInfo.profile.email);
        return false;
      }
    }
  };
  componentDidMount(): void {
    firebase.auth().onAuthStateChanged(user => {
      this.setState({ isSignedIn: !!user });
    });
  }

  render() {
    return (
      <LContext.Consumer>
        {({ l, currentl }) => (
          <Observer>
            {() => (
              <>
                <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} />
              </>
            )}
          </Observer>
        )}
      </LContext.Consumer>
    );
  }
}

export default OAuth;
