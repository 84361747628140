import React, { FC } from "react";

interface OwnProps {
  id?: string;
}

type Props = OwnProps;

const Box: FC<Props> = props => {
  return (
    <div
      id={props.id !== undefined ? props.id : ""}
      style={{
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "5px",
        paddingBottom: "10px",
        minWidth: "260px",
        overflow: "hidden",
        marginTop: "10px",
        border: "1px solid lightgrey",
        borderRadius: "4px",
        backgroundColor: "rgba(255,255,255,0.9)"
      }}
    >
      {props.children}
    </div>
  );
};

export default Box;
