import React, { Component, ReactNode, FC } from "react";
import { Menu, Icon, Button, Layout } from "antd";
import { withRouter, Link, NavLink, RouteComponentProps } from "react-router-dom";
import "./Navi.scss";

interface OwnProps extends RouteComponentProps {}

type Props = OwnProps;

type State = Readonly<{}>;

class Navi extends Component<Props, State> {
  readonly state: State = {};
  static defaultProps = {};
  render() {
    return (
      <Layout.Sider style={{ overflow: "auto", position: "fixed", height: "calc(100vh - 60px)", marginTop: "60px", padding: "10px" }}>
        <Menu
          mode={"inline"}
          theme={"dark"}
          style={{ borderTop: "1px solid lightgrey", marginTop: "25px" }}
          selectedKeys={["/" + this.props.location.pathname.split("/")[1]]}
          forceSubMenuRender
        >
          {this.props.children}
        </Menu>
      </Layout.Sider>
    );
  }
}

interface OwnPropsSubmenu {
  title: string;
  icon?: ReactNode;
}

export const SubMenu: FC<OwnPropsSubmenu> = props => {
  return (
    <Menu.SubMenu
      {...props}
      style={{ borderBottom: "1px solid lightgrey" }}
      title={
        props.icon !== undefined ? (
          <div>
            {props.icon}
            <span>{props.title}</span>
          </div>
        ) : (
          props.title
        )
      }
    >
      {props.children}
    </Menu.SubMenu>
  );
};

interface OwnPropsMenuItem {
  title: string;
  icon?: ReactNode;
  onClick?: (pathName?: string) => void;
  path?: string;
}

export const MenuItem: FC<OwnPropsMenuItem> = props => {
  return (
    <Menu.Item
      {...props}
      style={{ borderBottom: "1px solid lightgrey", margin: 0 }}
      onClick={() => {
        if (props.onClick !== undefined) props.onClick(props.path !== undefined ? props.path : "");
      }}
    >
      <>
        {props.path !== undefined ? (
          <NavLink style={{ color: "white" }} to={props.path}>
            {props.icon !== undefined ? props.icon : null}
            <span>{props.title}</span>
          </NavLink>
        ) : (
          <>
            {props.icon !== undefined ? props.icon : null}
            <span>{props.title}</span>
          </>
        )}
      </>
    </Menu.Item>
  );
};

export default withRouter(Navi);
