import React, { Component, FC } from "react";
import { LContext } from "../../Framework/Languagechanger/Languagechanger";
import { Form, Input, Steps, Button, Row, Col, Icon, Select, Card, message } from "antd";
import MediaQuery from "react-responsive";
import Box from "../../Framework/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faInfoCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import axios, { AxiosRequestConfig } from "axios";
import { observer, Observer } from "mobx-react";
import { observable, action } from "mobx";
import CountrySelect from "../../Framework/CountrySelect/CountrySelect";

const { Option, OptGroup } = Select;

interface OwnProps {
  id?: string;
}
interface IUser {
  VorName: string;
  NachName: string;
  EmailAdresse: string;
  RegBetreiber: string;
  Land: string;
  StrasseNummer: string;
  Ort: string;
  Telefon: string;
  Anrede: string;
  PLZ: string;
  WebAdresse: string;
}
type Props = OwnProps;

type State = Readonly<{
  currentstep: number;
  data: IUser;
  submitted: boolean;
  setdisabled: boolean;
  checkeduser: boolean;
}>;

@observer
class Anmeldung extends Component<Props, State> {
  readonly state: State = {
    currentstep: 0,
    data: {
      VorName: "",
      NachName: "",
      EmailAdresse: "",
      RegBetreiber: "",
      Land: "",
      StrasseNummer: "",
      Ort: "",
      Telefon: "",
      Anrede: "",
      PLZ: "",
      WebAdresse: ""
    },
    submitted: false,
    setdisabled: false,
    checkeduser: false
  };

  handleChange = (value: string, option: any) => {
    this.state.data[option] = value;
    this.forceUpdate();
  };

  static defaultProps = {
    id: ""
  };
  checkrequired = () => {
    let status = true;
    Object.values(this.state.data).map(v => {
      if (v == "") status = false;
    });
    return status;
  };
  save = (l: any) => {
    let datapath = Object.values(this.state.data)
      .map(v => {
        return "/" + (v as string).toString().trim();
      })
      .join("");

    let local = "";

    window.location.hostname === "localhost" ? (local = "http://localhost:2000") : (local = "");

    /*@action message.success(l("Datengespeichert"));*/

    axios.get(local + "/register" + datapath).then(response => {
      if (response.data) {
        this.setState({ checkeduser: true });
        message.success(l("DatenGesendet"));
      } else message.error(l("AnmeldungNichtErfolgreich"), 10);
    });
  };

  render() {
    return (
      <MediaQuery minWidth={550}>
        {matches => (
          <LContext.Consumer>
            {({ l, currentl }) => (
              <Observer>
                {() => (
                  <>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "45px", overflow: "hidden", minHeight: "calc(100vh - 125px)" }}>
                      <div style={{ backgroundColor: "transparent", display: "inline-block", width: "94%", maxWidth: "1200px" }}>
                        <Box id={this.props.id}>
                          <div
                            style={{
                              maxWidth: "800px",

                              margin: "0 auto"
                            }}
                          >
                            <Steps
                              current={this.state.currentstep}
                              style={{ marginTop: matches ? "20px" : "10px" }}
                              size={"small"}
                              direction={matches ? "horizontal" : "vertical"}
                            >
                              <Steps.Step
                                onClick={() => {
                                  if (!this.state.setdisabled) this.setState({ currentstep: 0 });
                                }}
                                title={l("Anmeldung")}
                                style={{ cursor: "pointer" }}
                              />
                              <Steps.Step
                                //style={{ cursor: "pointer" }}
                                onClick={() => {
                                  /*if (this.state.setdisabled) this.setState({ currentstep: 1 });*/
                                }}
                                title={l("ZugangAdmin")}
                              />
                            </Steps>
                            <div className={"steps-content"} style={{ marginTop: matches ? "16px" : "-12px" }}>
                              {this.state.currentstep === 0 || this.state.currentstep === 1 ? (
                                <>
                                  <h3 style={{ textAlign: "left", color: "grey", marginLeft: "14px" }}>
                                    {this.state.currentstep === 0 && !this.state.checkeduser ? (
                                      <>
                                        {l("Anmeldung")}
                                        <span style={{ marginLeft: "10px", cursor: "pointer" }}>
                                          <FontAwesomeIcon icon={faInfoCircle} size={"lg"} color={"#1890ff"} />
                                        </span>
                                      </>
                                    ) : this.state.currentstep === 1 && !this.state.checkeduser ? (
                                      <>
                                        {l("BitteDatenChecken")}
                                        <span style={{ marginLeft: "10px", cursor: "pointer" }}>
                                          <FontAwesomeIcon icon={faExclamationCircle} size={"lg"} color={"orange"} />
                                        </span>
                                      </>
                                    ) : // TODO: Check warum checkeduser, This condition will always return 'false' since the types 'false' and 'true' have no overlap.
                                    this.state.checkeduser ? (
                                      <>
                                        {l("DatenGesendet")}
                                        <span style={{ marginLeft: "10px" }}>
                                          <Icon type="check-circle" style={{ paddingRight: 4, color: "green" }} />
                                        </span>
                                      </>
                                    ) : null}
                                  </h3>
                                  <div style={{ width: "100%", backgroundColor: "transparent", border: "1px solid rgba(0, 0, 0, 0.1)", padding: "12px" }}>
                                    <Form layout={"vertical"}>
                                      <Row type={"flex"} gutter={32}>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                          <InputSubmitt
                                            forceUpdate={() => {
                                              this.forceUpdate();
                                            }}
                                            value={this.state.data}
                                            savename={"RegBetreiber"}
                                            label={l("RegBetreiber") + " *"}
                                            submitted={this.state.submitted}
                                            setdisabled={this.state.setdisabled}
                                          />
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                          <InputSubmitt
                                            forceUpdate={() => {
                                              this.forceUpdate();
                                            }}
                                            value={this.state.data}
                                            savename={"WebAdresse"}
                                            label={l("WebAdresse")}
                                            submitted={this.state.submitted}
                                            setdisabled={this.state.setdisabled}
                                          />
                                        </Col>
                                      </Row>
                                      <Row type={"flex"} gutter={32}>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                          <Form.Item label={l("Titel") + " *"}>
                                            <Select allowClear={true} value={this.state.data["Anrede"]} onChange={(v: any) => this.handleChange(v, "Anrede")}>
                                              <Option value={l("Frau")} key={l("Frau")} title="test">
                                                {l("Frau")}
                                              </Option>
                                              <Option value={l("Herr")} key={l("Herr")} title="test">
                                                {l("Herr")}
                                              </Option>
                                            </Select>
                                          </Form.Item>
                                          {/*<Form.Item label={l("Titel") + " *"}>
                                            <Select allowClear={true} showSearch>
                                              <Option value={l("Frau")}>{l("Frau")}</Option>
                                              <Option value={l("Herr")}>{l("Herr")}</Option>
                                            </Select>
                                          </Form.Item>*/}
                                        </Col>
                                      </Row>
                                      <Row type={"flex"} gutter={32}>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                          <InputSubmitt
                                            forceUpdate={() => {
                                              this.forceUpdate();
                                            }}
                                            value={this.state.data}
                                            savename={"VorName"}
                                            label={l("VorName") + " *"}
                                            submitted={this.state.submitted}
                                            setdisabled={this.state.setdisabled}
                                          />
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                          <InputSubmitt
                                            forceUpdate={() => {
                                              this.forceUpdate();
                                            }}
                                            value={this.state.data}
                                            savename={"NachName"}
                                            label={l("NachName") + " *"}
                                            submitted={this.state.submitted}
                                            setdisabled={this.state.setdisabled}
                                          />
                                        </Col>
                                      </Row>
                                      <Row type={"flex"} gutter={32}>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                          <InputSubmitt
                                            forceUpdate={() => {
                                              this.forceUpdate();
                                            }}
                                            value={this.state.data}
                                            savename={"EmailAdresse"}
                                            label={l("EmailAdresse") + " *"}
                                            submitted={this.state.submitted}
                                            setdisabled={this.state.setdisabled}
                                          />
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                          <InputSubmitt
                                            forceUpdate={() => {
                                              this.forceUpdate();
                                            }}
                                            value={this.state.data}
                                            savename={"Telefon"}
                                            label={l("Telefon") + " *"}
                                            submitted={this.state.submitted}
                                            setdisabled={this.state.setdisabled}
                                          />
                                        </Col>
                                      </Row>
                                      <Row type={"flex"} gutter={32}>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                          {/*<CountrySelect label={l("Land") + " *"} value={this.props.value} savename={"Land"} submitted={this.props.submitted} />*/}
                                          <InputSubmitt
                                            forceUpdate={() => {
                                              this.forceUpdate();
                                            }}
                                            value={this.state.data}
                                            savename={"Land"}
                                            label={l("Land") + " *"}
                                            submitted={this.state.submitted}
                                            setdisabled={this.state.setdisabled}
                                          />
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                          <InputSubmitt
                                            forceUpdate={() => {
                                              this.forceUpdate();
                                            }}
                                            value={this.state.data}
                                            savename={"StrasseNummer"}
                                            label={l("StrasseNummer") + " *"}
                                            submitted={this.state.submitted}
                                            setdisabled={this.state.setdisabled}
                                          />
                                        </Col>
                                      </Row>
                                      <Row type={"flex"} gutter={32}>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                          <InputSubmitt
                                            forceUpdate={() => {
                                              this.forceUpdate();
                                            }}
                                            value={this.state.data}
                                            savename={"PLZ"}
                                            label={l("PLZ") + " *"}
                                            submitted={this.state.submitted}
                                            setdisabled={this.state.setdisabled}
                                          />
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                          <InputSubmitt
                                            forceUpdate={() => {
                                              this.forceUpdate();
                                            }}
                                            value={this.state.data}
                                            savename={"Ort"}
                                            label={l("Ort") + " *"}
                                            submitted={this.state.submitted}
                                            setdisabled={this.state.setdisabled}
                                          />
                                        </Col>
                                      </Row>
                                      <Row type={"flex"} gutter={32}>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}></Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ textAlign: "left" }}>
                                          {this.state.currentstep === 0 ? (
                                            <Form.Item>
                                              <Button
                                                type={"primary"}
                                                style={{ float: "right", width: "100%", marginTop: "10px" }}
                                                onClick={() => {
                                                  if (this.checkrequired()) {
                                                    //if (true) {
                                                    this.setState({ submitted: true, currentstep: 1, setdisabled: true });
                                                    //this.setState({ setdisabled: true });

                                                    //this.setState({ currentstep: 3 });
                                                    console.log(this.state.data);
                                                  } else {
                                                    this.setState({ submitted: true });
                                                    console.log(this.state.data);
                                                  }
                                                }}
                                                className={"login-form-button"}
                                              >
                                                {l("Anmeldung")}
                                              </Button>
                                            </Form.Item>
                                          ) : null}
                                          {!this.state.checkeduser && this.checkrequired() && this.state.currentstep === 1 ? (
                                            <>
                                              <Form.Item>
                                                <Button
                                                  type={"primary"}
                                                  //icon={"check"}
                                                  style={{
                                                    float: "right",
                                                    backgroundColor: "darkorange",
                                                    borderColor: "darkorange",
                                                    width: "100%",
                                                    marginTop: "8px"
                                                  }}
                                                  onClick={() => {
                                                    this.save(l);
                                                  }}
                                                  className={"login-form-button"}
                                                >
                                                  {l("JetztSenden")}
                                                </Button>
                                              </Form.Item>
                                              <Form.Item>
                                                <Button
                                                  type={"primary"}
                                                  //icon={"check"}
                                                  style={{ float: "right", marginTop: "8px", width: "100%" }}
                                                  onClick={() => {
                                                    this.setState({ currentstep: 0, setdisabled: false });
                                                  }}
                                                  className={"login-form-button"}
                                                >
                                                  {l("DatenÄndern")}
                                                </Button>
                                              </Form.Item>
                                            </>
                                          ) : null}
                                        </Col>
                                      </Row>
                                    </Form>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div style={{ width: "100%", backgroundColor: "transparent", border: "1px solid rgba(0, 0, 0, 0.1)", padding: "12px" }}>
                                    <p>{l("AnmeldeAntwort_1")}</p>
                                    <p>{l("AnmeldeAntwort_2")}</p>
                                    <p>{l("IhrMBCTeam")}</p>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </Box>
                      </div>
                    </div>
                  </>
                )}
              </Observer>
            )}
          </LContext.Consumer>
        )}
      </MediaQuery>
    );
  }
}

interface OwnPropsInputSubmitt {
  value: any;
  savename: string;
  label?: string;
  submitted?: boolean;
  forceUpdate: () => void;
  disabled?: any;
  setdisabled?: boolean;
}

type PropsInputSubmitt = OwnPropsInputSubmitt;

export const InputSubmitt: FC<PropsInputSubmitt> = props => {
  return (
    <Observer>
      {() => (
        <>
          {props.label !== undefined ? (
            <Form.Item label={props.label}>
              {/*<label v> {l("VorNachname")}</label>*/}
              <Input
                allowClear={props.setdisabled ? false : true}
                disabled={props.setdisabled !== undefined ? props.setdisabled : false}
                className={props.submitted && props.value[props.savename] === "" ? "has-error" : ""}
                value={props.value[props.savename]}
                onChange={e => {
                  props.value[props.savename] = e.target.value;
                  props.forceUpdate();
                }}
              />
            </Form.Item>
          ) : (
            <Input
              allowClear={props.setdisabled ? false : true}
              className={props.submitted && props.value[props.savename] === "" ? "has-error" : ""}
              value={props.value[props.savename]}
              onChange={e => {
                props.value[props.savename] = e.target.value;
                props.forceUpdate();
              }}
            />
          )}
        </>
      )}
    </Observer>
  );
};

export default Anmeldung;
