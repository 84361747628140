import React, { Component, FC } from "react";
import Languagechanger, { LContext } from "./Languagechanger/Languagechanger";
import { Form, Input, Steps, Button, Row, Col, Icon, Select, Layout, Badge } from "antd";
import MediaQuery from "react-responsive";
import Box from "../Framework/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart, faTrash, faUserTimes, faUserCheck, faSearch, faBookOpen, faFileContract } from "@fortawesome/free-solid-svg-icons";
import axios, { AxiosRequestConfig } from "axios";
import { NavScroll, NavScrollItem } from "../NavScroll/NavScroll";
import { Link } from "react-router-dom";
import { OurFontAwsomeIcon } from "./OurComps/Icons/OurFontAwsomeIcon";
import { faAddressCard } from "@fortawesome/free-regular-svg-icons";
import LoginModal from "../Sites/LoginRegistrierung/LoginRegModal";

interface OwnProps {
  id?: string;
}

type Props = OwnProps;

type State = Readonly<{
  navopen: boolean;
}>;

class Header extends Component<Props, State> {
  readonly state: State = {
    navopen: false
  };

  render() {
    return (
      <MediaQuery minWidth={550}>
        {matches => (
          <LContext.Consumer>
            {({ l, currentl }) => (
              <Layout>
                <Layout.Header
                  style={{
                    backgroundColor: "#3b3f50",
                    position: "fixed",
                    zIndex: 1000,
                    height: "45px",
                    width: "100%",
                    padding: 0,
                    margin: 0,
                    top: 0,
                    left: 0
                  }}
                >
                  <Row type={"flex"} style={{ lineHeight: "normal", height: "45px", marginLeft: "10px", marginRight: "10px" }} align={"middle"}>
                    <Col span={4}>
                      <Button
                        onClick={() => {
                          this.setState({ navopen: !this.state.navopen });
                        }}
                      >
                        <Icon type={"menu"} />
                      </Button>

                      <Link to="./Hauptseite" style={{ color: "white", fontWeight: "bold", fontSize: "22px", marginLeft: "10px" }}>
                        <span>SEA</span>
                        <span style={{ color: "orange" }}>TRACKING</span>
                      </Link>
                    </Col>

                    <Col span={20} style={{ textAlign: "right", paddingRight: 2 }}>
                      {matches ? (
                        <span style={{ color: "white", fontWeight: "bold", marginLeft: "14px", marginRight: "40px" }}>
                          <span>
                            <Link to="./Hauptseite">
                              <FontAwesomeIcon icon={faSearch} size={"lg"} color={"white"} />
                            </Link>
                          </span>
                        </span>
                      ) : null}
                      <span style={{ paddingRight: matches ? "30px" : "18px" }}>
                        {/*<Link to="./Warenkorb">*/}
                        {/*<Badge count={4} style={{ backgroundColor: "darkorange", color: "#999", marginTop: "0px", marginLeft: "20px" }}>*/}
                        {/*<span onClick={() => this.setState({ ModalLogin: true })} style={{ cursor: "pointer" }}>
                      <FontAwesomeIcon icon={faUserTimes} size={"lg"} color={"white"} />
                    </span>*/}
                        {/* </Badge>*/}
                        {/*</Link>*/}
                        <LoginModal />
                      </span>
                      {/* <span style={{ paddingRight: matches ? "30px" : "16px" }}>
                        <Link to="./Warenkorb">
                          <Badge count={4} style={{ backgroundColor: "darkorange", color: "#999", marginTop: "0px", marginLeft: "20px" }}>
                          <FontAwesomeIcon icon={faShoppingCart} size={"lg"} color={"white"} />
                           </Badge>
                        </Link>
                      </span>*/}
                      {/* {matches ? <Languagechanger /> : null}*/}
                      <Languagechanger />
                      <span style={{ paddingLeft: matches ? 20 : 2, color: "white", fontWeight: "bold" }}></span>
                    </Col>
                    {/*<Col span={6} style={{ textAlign: "right", paddingRight: 30 }}>
                  <span style={{}}>mybeachcouch.com</span>
                </Col>*/}
                  </Row>
                </Layout.Header>

                <NavScroll
                  onNavClose={() => {
                    this.setState({ navopen: false });
                  }}
                  navOpen={this.state.navopen}
                >
                  <NavScrollItem
                    topath={"Hauptseite"}
                    scroll={false}
                    onClick={() => {
                      this.setState({ navopen: false });
                    }}
                  >
                    <OurFontAwsomeIcon icon={faSearch}>
                      <span style={{ paddingLeft: "4px", paddingRight: "10px", color: "white" }}>{l("NStartseite")}</span>
                    </OurFontAwsomeIcon>
                  </NavScrollItem>
                  <NavScrollItem
                    topath={"MeineBuchungen"}
                    scroll={false}
                    onClick={() => {
                      this.setState({ navopen: false });
                    }}
                  >
                    <OurFontAwsomeIcon icon={faBookOpen}>
                      <span style={{ paddingLeft: "4px", paddingRight: "10px", color: "white" }}>{l("NMeineBuchungen")}</span>
                    </OurFontAwsomeIcon>
                  </NavScrollItem>
                  <NavScrollItem
                    topath={"MeinAccount"}
                    scroll={false}
                    onClick={() => {
                      this.setState({ navopen: false });
                    }}
                  >
                    <OurFontAwsomeIcon icon={faUserCheck}>
                      <span style={{ paddingLeft: "4px", paddingRight: "10px", color: "white" }}>{l("NMeinAccount")}</span>
                    </OurFontAwsomeIcon>
                  </NavScrollItem>
                  <NavScrollItem
                    topath={"Impressum"}
                    onClick={() => {
                      this.setState({ navopen: false });
                    }}
                  >
                    <OurFontAwsomeIcon icon={faAddressCard}>
                      <span style={{ paddingLeft: "4px", paddingRight: "10px", color: "white" }}>{l("Impressum")}</span>
                    </OurFontAwsomeIcon>
                  </NavScrollItem>
                  <NavScrollItem
                    topath={"Startseite"}
                    scroll={false}
                    onClick={() => {
                      this.setState({ navopen: false });
                    }}
                  >
                    <OurFontAwsomeIcon icon={faFileContract} color={"orange"}>
                      <span style={{ paddingLeft: "4px", paddingRight: "10px", color: "orange" }}>{l("NAnbieterSeite")}</span>
                    </OurFontAwsomeIcon>
                  </NavScrollItem>
                </NavScroll>
              </Layout>
            )}
          </LContext.Consumer>
        )}
      </MediaQuery>
    );
  }
}

export default Header;
