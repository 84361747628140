import React, { Component, FC } from "react";
import Languagechanger, { LContext } from "../../Framework/Languagechanger/Languagechanger";
import Buchung from "../BuchungsSeite/Buchung";
import { Button, Col, Drawer, Icon, Input, Layout, Row, Modal, Tooltip, Form, Tabs, Calendar, Badge, Select, AutoComplete, Spin } from "antd";
import { HashLink as AnchorLink } from "react-router-hash-link";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import Box from "../../Framework/Box";
import "react-datepicker/dist/react-datepicker.css";
import Myicon from "../../Icons/Myicon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import axios, { AxiosRequestConfig } from "axios";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { observer, Observer } from "mobx-react";
import { observable } from "mobx";

const { TabPane } = Tabs;

interface OwnProps extends RouteComponentProps {}

type Props = OwnProps;

type State = Readonly<{
  navopen: boolean;
  dataSource: any[];
}>;

class MeineBuchungen extends Component<Props, State> {
  readonly state: State = {
    navopen: false,
    dataSource: []
  };

  render() {
    // @ts-ignore
    return (
      <LContext.Consumer>
        {({ l, currentl }) => (
          <>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "45px", overflow: "hidden", minHeight: "calc(100vh - 125px)" }}>
              <div style={{ backgroundColor: "transparent", display: "inline-block", width: "94%" }}>
                <Box>
                  <Row type={"flex"}>
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      md={{ span: 20, offset: 2 }}
                      lg={{ span: 16, offset: 4 }}
                      xl={{ span: 12, offset: 6 }}
                      xxl={{ span: 10, offset: 7 }}
                      style={{ textAlign: "center" }}
                    >
                      <PoolView />
                    </Col>
                  </Row>
                </Box>
              </div>
            </div>
          </>
        )}
      </LContext.Consumer>
    );
  }
}

interface OwnPropsPoolView {}

type PropsPoolView = OwnPropsPoolView;

type StatePoolView = Readonly<{
  modalopen: boolean;
  imageindex: number;
  selectedItem: number;
  selectedTab: string;
}>;
@observer
class PoolView extends Component<PropsPoolView, StatePoolView> {
  readonly state: StatePoolView = {
    modalopen: false,
    imageindex: 0,
    selectedItem: 0,
    selectedTab: "1"
  };
  static defaultProps = {};
  statusFormatter = (currrent: number, all: number) => {
    return currrent + " / " + all;
  };

  render() {
    return (
      <LContext.Consumer>
        {({ l, currentl }) => (
          <>
            <div style={{ width: "100%", display: "inline-block" }}>
              <div style={{ textAlign: "center" }}>
                <h2>{l("NMeineBuchungen")}</h2>
              </div>
            </div>
            <div style={{ width: "100%", display: "inline-block" }}>
              <div style={{ float: "left", textAlign: "left", width: "100%" }}>
                <div className="card-container">
                  <Tabs onChange={(v: string) => this.setState({ selectedTab: v })} activeKey={this.state.selectedTab} type="card">
                    <TabPane
                      tab={
                        <span>
                          <FontAwesomeIcon icon={faCalendarAlt} size={"lg"} color={"black"} />
                          <span style={{ marginLeft: "6px" }}>{l("BuchungenOffen")}</span>
                        </span>
                      }
                      key="1"
                      style={{}}
                    >
                      <table style={{ height: "100%", backgroundColor: "white", width: "100%" }}>
                        <tr>
                          <td>
                            <p style={{ width: "100%", backgroundColor: "#f0f2f5", padding: "4px", color: "black" }}>23.12.2019</p>
                            <div style={{ float: "left", width: "75%" }}>
                              <p style={{ fontWeight: "bold" }}>Liege mit Schirm (2x)</p>
                              <p style={{ marginTop: "-16px" }}>Calimera Beach Resort</p>
                              <p style={{ marginTop: "-10px" }}>
                                <Button>{l("BuchungsDetails")}</Button>
                              </p>
                            </div>
                            <div style={{ float: "right", width: "25%" }}>
                              <Myicon height={""} name={"pool_1"} />
                            </div>
                            &nbsp;
                          </td>
                        </tr>
                      </table>
                      <table style={{ height: "100%", backgroundColor: "white", width: "100%" }}>
                        <tr>
                          <td>
                            <p style={{ width: "100%", backgroundColor: "#f0f2f5", padding: "4px", color: "black" }}>23.12.2019</p>
                            <div style={{ float: "left", width: "75%" }}>
                              <p style={{ fontWeight: "bold" }}>Liege mit Schirm (2x)</p>
                              <p style={{ marginTop: "-16px" }}>Calimera Beach Resort</p>
                              <p style={{ marginTop: "-10px" }}>
                                <Button>{l("BuchungsDetails")}</Button>
                              </p>
                            </div>
                            <div style={{ float: "right", width: "25%" }}>
                              <Myicon height={""} name={"pool_1"} />
                            </div>
                            &nbsp;
                          </td>
                        </tr>
                      </table>
                    </TabPane>
                    <TabPane
                      tab={
                        <span>
                          <FontAwesomeIcon icon={faCalendarAlt} size={"lg"} color={"black"} />
                          <span style={{ marginLeft: "6px" }}>{l("BuchungenVergangene")}</span>
                        </span>
                      }
                      key="2"
                    >
                      <Buchung onCancel={() => this.setState({ selectedTab: "1" })} />
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </div>
          </>
        )}
      </LContext.Consumer>
    );
  }
}

export default withRouter(MeineBuchungen);
