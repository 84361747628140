import React, { Component, FC } from "react";
import Languagechanger, { LContext } from "../../Framework/Languagechanger/Languagechanger";
import Buchung from "./Buchung";
import Header from "../../Framework/Header";
import { Button, Col, Drawer, Icon, Input, Layout, Row, Modal, Tooltip, Form, Tabs, Calendar, Badge, Select, AutoComplete, Spin } from "antd";

import { HashLink as AnchorLink } from "react-router-hash-link";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import { NavScroll, NavScrollItem } from "../../NavScroll/NavScroll";

import Box from "../../Framework/Box";

import "react-datepicker/dist/react-datepicker.css";
import { Carousel } from "react-responsive-carousel";
import Myicon from "../../Icons/Myicon";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImages, faCalendarAlt, faAddressCard } from "@fortawesome/free-regular-svg-icons";
import { faCartArrowDown, faMapMarkerAlt, faShoppingCart, faUmbrellaBeach, faClipboardList, faBox, faCog, faSearch } from "@fortawesome/free-solid-svg-icons";
import { OurFontAwsomeIcon } from "../../Framework/OurComps/Icons/OurFontAwsomeIcon";
import axios, { AxiosRequestConfig } from "axios";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { observer, Observer } from "mobx-react";
import { observable } from "mobx";

const { TabPane } = Tabs;

const { Option } = Select;

const { Search } = Input;

const yearanzeige = new Date().getFullYear();

const searcharray: any = ["d", "d", "Club Calmeria, Türkei, Belek", "Test", "Club Test, Türkei, Belek"];

const childrenarray: any = ["Liege", "Pavillion", "Strandkorb"];

const children: any = [];

// @ts-ignore
childrenarray.map(val => {
  children.push(<Option key={val}>{val}</Option>);
});

interface OwnProps extends RouteComponentProps {}

type Props = OwnProps;

type State = Readonly<{
  navopen: boolean;
  searcharray: any[];
  dataSource: any[];
  hotelData: any[];
  fetching: boolean;
}>;

class MainWebsite extends Component<Props, State> {
  readonly state: State = {
    navopen: false,
    searcharray: [],
    dataSource: [],
    hotelData: [],
    fetching: false
  };
  searchdebouncer = new Subject();
  static defaultProps = {};
  constructor(props: any) {
    super(props);

    this.searchdebouncer.pipe(debounceTime(300)).subscribe(x => this.fetchUser(x as string));
  }

  fetchUser = (value: string) => {
    if (value.length >= 2) {
      this.setState({ hotelData: [], fetching: true });

      let local = "";

      if (window.location.hostname === "localhost") {
        local = "http://localhost:2000";
      }
      axios.get(local + "/searchHotelsBeach/" + value).then(response => {
        console.log(response.data);
        if (response.data) this.setState({ hotelData: response.data, fetching: false });
        else alert("Fehler");
      });
    } else {
      this.setState({ hotelData: [] });
    }
  };

  render() {
    // @ts-ignore
    return (
      <LContext.Consumer>
        {({ l, currentl }) => (
          <>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "45px", overflow: "hidden", minHeight: "calc(100vh - 125px)" }}>
              <div style={{ backgroundColor: "transparent", display: "inline-block", width: "94%" }}>
                {/* <h2 style={{ textAlign: "center", marginTop: "14px" }}>{l("HSBuchung_Ü")}</h2>*/}
                {/*<Anmeldung id={"Anmeldung"} />*/}

                {/*{l("HSBuchung_Ü")}*/}
                {/* <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                  <div
                    style={{
                      width: "100%",
                      maxWidth: "600px",
                      backgroundColor: "darkorange",
                      borderRadius: "4px",
                      marginTop: "10px"
                    }}
                  >
                    <table
                      style={{
                        width: "100%",
                        color: "white"
                      }}
                    >
                      <tbody>
                        <tr style={{ padding: "4px" }}>
                          <td colSpan={2}>
                            <h2 style={{ color: "white", textAlign: "center" }}>{l("HSBuchung_Ü1")}</h2>
                            <p style={{ color: "white", textAlign: "center", marginTop: "-10px" }}>{l("HSSuche_Ü")}</p>
                          </td>
                        </tr>
                        <tr style={{ padding: "4px" }}>
                          <td colSpan={2} style={{ padding: "4px", paddingBottom: "20px" }}>
                            <Input allowClear={true} style={{}} onChange={this.handleSearch} />
                            <Select
                              mode="multiple"
                              placeholder={l("HSSuche_PH")}
                              showSearch={true}
                              onChange={e => {
                                console.log(e);
                              }}
                              onSearch={e => this.searchdebouncer.next(e)}
                              notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
                              style={{ width: "100%", marginTop: "-20px" }}
                              filterOption={false}
                            >
                              {this.state.hotelData.map(v => (
                                <Option key={v.id} value={v.id}>
                                  {v.RegBetreiber}
                                </Option>
                              ))}
                            </Select>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                </div>*/}

                {/*<div style={{ textAlign: "center" }}>
                    <h2 style={{ display: "inline", paddingRight: "20px" }}>{l("Kinderpool")}</h2>
                    <Button type={"primary"}>test</Button>
                  </div>*/}
                <Row type={"flex"}>
                  <Col span={24}>
                    <PoolView />
                  </Col>
                </Row>
              </div>
            </div>
          </>
        )}
      </LContext.Consumer>
    );
  }
}

interface OwnPropsDrawerMenuNavItem extends RouteComponentProps {
  onClick?: () => void;
  topath?: string;
}

type PropsDrawerMenuNavItem = OwnPropsDrawerMenuNavItem;

const DrawerMenuNavIteminner: FC<PropsDrawerMenuNavItem> = props => {
  return (
    <>
      <AnchorLink
        scroll={el => {
          //el.scrollIntoView({ behavior: "smooth", block: "start" });
          window.scrollTo({ left: 0, top: el.offsetTop - 45, behavior: "smooth" });
        }}
        to={props.location.pathname + "#" + (props.topath !== undefined ? props.topath : "")}
      >
        <div onClick={props.onClick} className={"DrawerMenuNavItem"}>
          {props.children}
        </div>
      </AnchorLink>
    </>
  );
};

const DrawerMenuNavItem = withRouter(DrawerMenuNavIteminner);

interface OwnPropsPoolView {}

type PropsPoolView = OwnPropsPoolView;

type StatePoolView = Readonly<{
  modalopen: boolean;
  imageindex: number;
  selectedItem: number;
  selectedTab: string;
}>;
@observer
class PoolView extends Component<PropsPoolView, StatePoolView> {
  readonly state: StatePoolView = {
    modalopen: false,
    imageindex: 0,
    selectedItem: 0,
    selectedTab: "1"
  };
  static defaultProps = {};

  statusFormatter = (currrent: number, all: number) => {
    return currrent + " / " + all;
  };

  render() {
    return (
      <LContext.Consumer>
        {({ l, currentl }) => (
          <>
            <div style={{ width: "100%", display: "inline-block" }}>
              {/*<div style={{ float: "right" }}>
                          <Button type={"primary"}>test</Button>
                        </div>*/}
            </div>
            <div style={{ width: "100%", display: "inline-block" }}>
              <div style={{ float: "left", textAlign: "left", width: "100%" }}>
                <div className="card-container">
                  <Tabs onChange={(v: string) => this.setState({ selectedTab: v })} activeKey={this.state.selectedTab} type="card">
                    <TabPane
                      tab={
                        <span>
                          <FontAwesomeIcon icon={faClipboardList} size={"lg"} color={"black"} />
                          <span style={{ marginLeft: "6px", color: "black", fontWeight: "normal" }}>{l("Containerlist")}</span>
                        </span>
                      }
                      key="1"
                      style={{}}
                    >
                      <Carousel
                        swipeScrollTolerance={35}
                        transitionTime={250}
                        useKeyboardArrows
                        statusFormatter={this.statusFormatter}
                        showIndicators={false}
                        showThumbs={false}
                        selectedItem={this.state.imageindex}
                      >
                        <div onClick={() => this.setState({ imageindex: 0, modalopen: true })}>
                          <Myicon height={"100%"} name={"pool_1"} />
                        </div>

                        <div onClick={() => this.setState({ imageindex: 1, modalopen: true })}>
                          <Myicon height={"100%"} name={"liege1"} />
                        </div>
                      </Carousel>
                    </TabPane>
                    <TabPane
                      tab={
                        <span>
                          <FontAwesomeIcon icon={faSearch} size={"lg"} color={"black"} />
                          <span style={{ marginLeft: "6px", color: "black", fontWeight: "normal" }}>{l("manuelleSuche")}</span>
                        </span>
                      }
                      key="2"
                    >
                      <p>Content of Tab Pane 3</p>
                      <p>Content of Tab Pane 3</p>
                      <p>Content of Tab Pane 3</p>
                    </TabPane>
                    <TabPane
                      tab={
                        <span>
                          <FontAwesomeIcon icon={faCog} size={"lg"} color={"black"} />
                          <span style={{ marginLeft: "6px", color: "black", fontWeight: "normal" }}>{l("Einstellungen")}</span>
                        </span>
                      }
                      key="3"
                    >
                      <p>Content of Tab Pane 3</p>
                      <p>Content of Tab Pane 3</p>
                      <p>Content of Tab Pane 3</p>
                    </TabPane>
                    <TabPane
                      tab={
                        <span>
                          <FontAwesomeIcon icon={faCalendarAlt} size={"lg"} color={"black"} />
                          <span style={{ marginLeft: "6px" }}>{l("Buchung")}</span>
                        </span>
                      }
                      key="4"
                    >
                      <Buchung onCancel={() => this.setState({ selectedTab: "1" })} />
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </div>
            <Modal
              closable={false}
              onCancel={() => this.setState({ modalopen: false })}
              visible={this.state.modalopen}
              footer={[
                <Button key="back" onClick={() => this.setState({ modalopen: false })}>
                  {l("Schließen")}
                </Button>
              ]}
            >
              <Carousel
                swipeScrollTolerance={35}
                transitionTime={250}
                useKeyboardArrows
                statusFormatter={this.statusFormatter}
                showIndicators={false}
                showThumbs={false}
                selectedItem={this.state.imageindex}
              >
                <div>
                  <Myicon height={"100%"} name={"pool_1"} />
                </div>

                <div>
                  <Myicon height={"100%"} name={"liege1"} />
                </div>
              </Carousel>
              {/*<MyCarousel selectedItem={this.state.imageindex} />*/}
            </Modal>
          </>
        )}
      </LContext.Consumer>
    );
  }
}

export default withRouter(MainWebsite);
