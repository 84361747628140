import React, { Component, Ref } from "react";
import { LContext } from "../../Languagechanger/Languagechanger";
import moment from "moment";
import { IReactComponent, observer, Observer } from "mobx-react";
import { observable, computed, action } from "mobx";
import "./OurMultiDatePickerStyle.scss";
import { Button, Col, Divider, Row } from "antd";
import { OurFontAwsomeIcon } from "../Icons/OurFontAwsomeIcon";
import { faAngleLeft, faAngleRight, faBackward, faForward } from "@fortawesome/free-solid-svg-icons";

interface OurDayListItem {
  status?: boolean;
  date?: string;
  day?: number;
  disabled?: boolean;
}
class Calendar {
  @observable picked: string[];
  @observable currentMonth: number;
  @observable currentYear: number;
  from: moment.Moment;
  to: moment.Moment;
  constructor(currentMonth: number, currentYear: number, from: moment.Moment, to: moment.Moment, picked: string[]) {
    this.currentMonth = currentMonth;
    this.currentYear = currentYear;
    this.from = from;
    this.to = to;
    this.picked = picked;
  }

  @computed get dayList() {
    // @ts-ignore
    let currentdate = moment(this.currentYear + "-" + (this.currentMonth + 1) + "-01", "YYYY-MM-DD");
    let month = currentdate.month();
    let dates: Array<Array<OurDayListItem>> = [[], [], [], [], [], [], []];
    let firstday = currentdate.day() == 0 ? 6 : currentdate.day() - 1;
    for (let i = 0; i < firstday; i++) {
      dates[i].push({ status: false, day: i * 100 });
    }

    while (currentdate.month() === month) {
      let day = currentdate.format("YYYY-MM-DD");
      let disabled = currentdate.isBefore(this.from) || currentdate.isAfter(this.to);
      dates[currentdate.day() == 0 ? 6 : currentdate.day() - 1].push({ date: day, day: currentdate.date(), status: true, disabled });
      currentdate.add(1, "days");
    }
    return dates;
  }
}

const month = ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"];

interface OwnPropsOurMultiDatePicker {
  from: moment.Moment;
  to: moment.Moment;
  now: moment.Moment;
  onChange: (picked: string[]) => void;
  withClear: boolean;
  value: string[];
}

type PropsOurMultiDatePicker = OwnPropsOurMultiDatePicker;

@observer
class OurDatepicker extends Component<PropsOurMultiDatePicker> {
  static defaultProps = {
    from: moment().startOf("day"),
    to: moment("2199-12-31"),
    now: moment(),
    onChange: () => {},
    withClear: false,
    value: []
  };
  calendar: Calendar;
  constructor(props: PropsOurMultiDatePicker) {
    super(props);
    this.calendar = new Calendar(moment().month(), moment().year(), props.from, props.to, props.value);
  }
  weekdayName = (d: number): string => {
    if (d === 0) {
      return "MO";
    } else if (d === 1) {
      return "DI";
    } else if (d === 2) {
      return "MI";
    } else if (d === 3) {
      return "DO";
    } else if (d === 4) {
      return "FR";
    } else if (d === 5) {
      return "SA";
    } else if (d === 6) {
      return "SO";
    } else return "";
  };
  @action handleDateSelect = (d: string = "") => {
    let index: number = this.calendar.picked.findIndex(dinner => d === dinner);

    if (index !== -1) this.calendar.picked.splice(index, 1);
    else this.calendar.picked.push(d);
    this.props.onChange(this.calendar.picked);
  };

  @action clear = () => {
    this.calendar.picked.splice(0, this.calendar.picked.length);
    this.props.onChange(this.calendar.picked);
  };

  render() {
    return (
      <LContext.Consumer>
        {({ l, currentl }) => (
          <>
            <Observer>
              {() => (
                <div style={{ width: "256px" }}>
                  <div style={{ display: "flex", flexWrap: "nowrap", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", flexWrap: "nowrap", justifyContent: "space-between", alignItems: "center", width: "125px" }}>
                      <OurFontAwsomeIcon
                        size={"2x"}
                        color={" #1890ff"}
                        style={{ cursor: "pointer" }}
                        icon={faAngleLeft}
                        onClick={() => {
                          if (this.calendar.currentMonth > 0) this.calendar.currentMonth--;
                          else {
                            this.calendar.currentMonth = 11;
                            this.calendar.currentYear--;
                          }
                        }}
                      />
                      <div>{month[this.calendar.currentMonth]}</div>
                      <OurFontAwsomeIcon
                        size={"2x"}
                        color={" #1890ff"}
                        style={{ cursor: "pointer" }}
                        icon={faAngleRight}
                        onClick={() => {
                          if (this.calendar.currentMonth < 11) this.calendar.currentMonth++;
                          else {
                            this.calendar.currentMonth = 0;
                            this.calendar.currentYear++;
                          }
                        }}
                      />
                    </div>
                    <div style={{ display: "flex", flexWrap: "nowrap", justifyContent: "space-between", alignItems: "center", width: "125px" }}>
                      <OurFontAwsomeIcon
                        size={"2x"}
                        color={" #1890ff"}
                        style={{ cursor: "pointer" }}
                        icon={faAngleLeft}
                        onClick={() => {
                          if (this.calendar.currentYear > moment().year()) this.calendar.currentYear--;
                        }}
                      />
                      <div>{this.calendar.currentYear}</div>
                      <OurFontAwsomeIcon
                        size={"2x"}
                        color={" #1890ff"}
                        style={{ cursor: "pointer" }}
                        icon={faAngleRight}
                        onClick={() => {
                          this.calendar.currentYear++;
                        }}
                      />
                    </div>
                  </div>
                  <Divider style={{ margin: 0, marginTop: "4px", borderColor: "grey" }} />
                  <div style={{ display: "flex", flexWrap: "nowrap" }}>
                    {this.calendar.dayList.map((list: OurDayListItem[], index: number) => (
                      <div key={index} style={{ width: "27px", margin: "5px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <div> {l(this.weekdayName(index))}</div>

                        {list.map((d: OurDayListItem) => {
                          if (d.status) {
                            return (
                              <div
                                key={d.date}
                                onClick={() => {
                                  if (d.disabled !== true) this.handleDateSelect(d.date);
                                }}
                                style={{
                                  cursor: "pointer",
                                  width: "27px",
                                  height: "27px",
                                  margin: "5px",
                                  textAlign: "center",
                                  paddingTop: "3px",
                                  color: d.disabled === true ? "lightgrey" : "black"
                                }}
                                className={
                                  this.calendar.picked.findIndex(dinner => d.date === dinner) !== -1
                                    ? "dateboxpicked"
                                    : d.disabled === true
                                    ? ""
                                    : "dateboxhighlight"
                                }
                              >
                                {d.day}
                              </div>
                            );
                          } else return <div key={d.day} style={{ width: "27px", height: "27px", margin: "5px" }} />;
                        })}
                      </div>
                    ))}
                  </div>
                  {this.props.withClear ? (
                    <Button
                      style={{ width: "100px" }}
                      type={"primary"}
                      onClick={() => {
                        this.clear();
                      }}
                    >
                      {l("Löschen")}
                    </Button>
                  ) : null}
                </div>
              )}
            </Observer>
          </>
        )}
      </LContext.Consumer>
    );
  }
}

export default OurDatepicker;
