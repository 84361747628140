import React, { Component, FC } from "react";
import Languagechanger, { LContext } from "./Languagechanger/Languagechanger";
import { Form, Input, Steps, Button, Row, Col, Icon, Select, Layout, Badge } from "antd";
import MediaQuery from "react-responsive";
import Box from "../Framework/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faTrash,
  faUserTimes,
  faUserCheck,
  faSearch,
  faBookOpen,
  faFileContract,
  faClipboardList,
  faCalculator,
  faArrowRight,
  faUserCog
} from "@fortawesome/free-solid-svg-icons";
import axios, { AxiosRequestConfig } from "axios";
import { NavScroll, NavScrollItem } from "../NavScroll/NavScroll";
import { Link } from "react-router-dom";
import { OurFontAwsomeIcon } from "./OurComps/Icons/OurFontAwsomeIcon";
import { faAddressCard, faCheckCircle, faEdit } from "@fortawesome/free-regular-svg-icons";
import LoginModal from "../Sites/LoginRegistrierung/LoginRegModal";

interface OwnProps {
  id?: string;
}

type Props = OwnProps;

type State = Readonly<{
  navopen: boolean;
}>;

class HeaderAnbieter extends Component<Props, State> {
  readonly state: State = {
    navopen: false
  };

  render() {
    return (
      <MediaQuery minWidth={550}>
        {matches => (
          <LContext.Consumer>
            {({ l, currentl }) => (
              <Layout>
                <Layout.Header
                  style={{
                    backgroundColor: "#1890ff",
                    position: "fixed",
                    zIndex: 1000,
                    height: "45px",
                    width: "100%",
                    padding: 0,
                    margin: 0,
                    top: 0,
                    left: 0
                  }}
                >
                  <Row type={"flex"} style={{ lineHeight: "normal", height: "45px", marginLeft: "10px", marginRight: "10px" }} align={"middle"}>
                    <Col span={4}>
                      <Button
                        onClick={() => {
                          this.setState({ navopen: !this.state.navopen });
                        }}
                      >
                        <Icon type={"menu"} />
                      </Button>
                    </Col>

                    <Col span={20} style={{ textAlign: "right", paddingRight: 5 }}>
                      {matches ? (
                        <span style={{ paddingRight: "40px" }}>
                          <Link to="./">
                            <Button
                              style={{ color: "#1890ff", fontWeight: "bold" }}
                              onClick={() => {
                                //this.data.mybeachanmeldung = 2;
                              }}
                            >
                              {l("Login")}
                              <span style={{ marginLeft: "10px" }}>
                                <FontAwesomeIcon icon={faUserCog} size={"lg"} color={"#1890ff"} />
                              </span>
                            </Button>
                          </Link>
                        </span>
                      ) : (
                        <span style={{ paddingRight: "30px" }}>
                          <Link to="./">
                            <span style={{ cursor: "pointer" }}>
                              <FontAwesomeIcon icon={faUserCog} size={"lg"} color={"white"} />
                            </span>
                          </Link>
                        </span>
                      )}
                      {/*<span style={{ paddingRight: 20 }}>{this.state.user === undefined ? "" : this.state.user.name}</span>*/}
                      <Languagechanger />
                      <span style={{ paddingLeft: 10, color: "white", fontWeight: "bold" }}>mybeachcouch.com</span>
                    </Col>
                    {/*<Col span={6} style={{ textAlign: "right", paddingRight: 30 }}>
                    <span style={{}}>mybeachcouch.com</span>
                  </Col>*/}
                  </Row>
                </Layout.Header>
                <NavScroll
                  onNavClose={() => {
                    this.setState({ navopen: false });
                  }}
                  navOpen={this.state.navopen}
                >
                  <NavScrollItem
                    topath={"Anmeldung"}
                    scroll={false}
                    onClick={() => {
                      this.setState({ navopen: false });
                    }}
                  >
                    <OurFontAwsomeIcon icon={faClipboardList}>
                      <div style={{ color: "white" }}>{l("Anmeldung")}</div>
                    </OurFontAwsomeIcon>
                  </NavScrollItem>
                  <NavScrollItem
                    topath={"Vorteile"}
                    onClick={() => {
                      this.setState({ navopen: false });
                    }}
                  >
                    <OurFontAwsomeIcon icon={faCheckCircle}>
                      <div style={{ color: "white" }}>{l("Vorteile")}</div>
                    </OurFontAwsomeIcon>
                  </NavScrollItem>
                  <NavScrollItem
                    topath={"Kalkulator"}
                    onClick={() => {
                      this.setState({ navopen: false });
                    }}
                  >
                    <OurFontAwsomeIcon icon={faCalculator}>
                      <span style={{ color: "white" }}>{l("Kalkulator")}</span>
                    </OurFontAwsomeIcon>
                  </NavScrollItem>
                  <NavScrollItem
                    topath={"AdminTool"}
                    onClick={() => {
                      this.setState({ navopen: false });
                    }}
                  >
                    <OurFontAwsomeIcon icon={faEdit}>
                      <span style={{ color: "white" }}>{l("AdminTool")}</span>
                    </OurFontAwsomeIcon>
                  </NavScrollItem>
                  <NavScrollItem
                    topath={"Impressum"}
                    onClick={() => {
                      this.setState({ navopen: false });
                    }}
                  >
                    <OurFontAwsomeIcon icon={faAddressCard}>
                      <span style={{ paddingRight: "10px", color: "white" }}>{l("Impressum")}</span>
                    </OurFontAwsomeIcon>
                  </NavScrollItem>
                  <NavScrollItem
                    topath={"Hauptseite"}
                    scroll={false}
                    onClick={() => {
                      this.setState({ navopen: false });
                    }}
                  >
                    <OurFontAwsomeIcon icon={faSearch} color={"orange"}>
                      <span style={{ paddingLeft: "4px", paddingRight: "10px", color: "orange" }}>{l("NStartseite")}</span>
                    </OurFontAwsomeIcon>
                  </NavScrollItem>
                </NavScroll>
              </Layout>
            )}
          </LContext.Consumer>
        )}
      </MediaQuery>
    );
  }
}

export default HeaderAnbieter;
